/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react'
import './supplier.scss'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import FormControlLabel from '@mui/material/FormControlLabel';

import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Sign from '../../assets/image/dummyImg.png';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { AddsupplierSchema } from '../../components/schema/Addsupplierschema'
import { useParams } from 'react-router-dom';
import Api from '../../service/Api'

const api = new Api()
const initialValues = {
    
    name: "",
    email: "",
    phoneNo: "",
    city: "",
    line1: "",
    line2: "",  
    firmName:"",     
    pincode: "",
    state: "",
    gstIn: "",
    panNo: "",
    hasGst: false
};

export default function AddSupplier() {
    const [age, setAge] = React.useState('');
    const [checked, setChecked] = useState(false);
    const [text, setText] = useState("");
    const navigate = useNavigate();
    const dataFetchedRef = useRef(false);
    const params = useParams();
    const { id } = params;
    const { values, errors, touched, handleChange, handleSubmit,setValues } = useFormik({
        initialValues: initialValues,
        validationSchema: AddsupplierSchema,
        onSubmit: () => {
            if (id)
 {
                postFetchData();
            } else {
                addUserDetails();
            }

        }

    })


    const addUserDetails = async () => {
        try {
            const defaultValue = {
                name: values.name,
                email: values.email,
                phoneNo: values.phoneNo,
                firmName: values.firmName,
                gstIn: values.gstIn,
                panNo: values.panNo,
                line1: values.line1,
                line2:values.line2,
                state: values.state,
                city: values.city,
                pincode: values.pincode,
                hasGst: values.hasGst
            }

            let address = {
                line1: defaultValue.line1,
                line2: defaultValue.line2,
                pincode: defaultValue.pincode,
                state: defaultValue.state,
                city: defaultValue.city
            }
            let gstDetails = {
                firmName: defaultValue.firmName,
                gstIn: defaultValue.gstIn,
                panNo: defaultValue.panNo,
            }

            const body = {
                name: defaultValue.name,
                email: defaultValue.email,
                phoneNo: defaultValue.phoneNo,
                hasGst: defaultValue.hasGst,
                gstDetails,
                address,
            }
            console.log("body", body)
            let response = await api.put('supplier', body)
            if (response) {
                navigate('/supplier')
            }
        }

        catch (err) {
            console.error(err);
        }
    }
  

    const handleSelectChange = (event) => {
        setAge(event.target.value);
    };
  
    async function fetchData() {
        try {
            let response = await api.get('supplier', { supplierId: id })
            console.log('Response', response)
            if (response.status === 200) {
                console.log('Response is', response.data.data)

                //   console.log(response.data.data[0].name) 

                const defaultValue = {
                    name: response.data.data[0].name,
                    email: response.data.data[0].email,
                    phoneNo: response.data.data[0].phoneNo,
                    hasGst: response.data.data[0].hasGst,
                    gstIn: response.data.data[0].gstDetails.gstIn,
                    panNo: response.data.data[0].gstDetails.panNo,
                    line1: response.data.data[0].address.line1,
                    line2: response.data.data[0].address.line2,
                    city: response.data.data[0].address.city,
                    state: response.data.data[0].address.state,
                    pincode: response.data.data[0].address.pincode,
                    firmName: response.data.data[0].gstDetails.firmName,

                }
                setValues(defaultValue)
                setChecked(!checked)
            }
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        if (id)
 {
            fetchData();
        }

    }, [])

    async function postFetchData() {
        try {
            console.log("Add Client Post Values ", values)

            const defaultValue = {
                gstIn: values.gstIn,
                panNo: values.panNo,
                firmName:values.firmName,
                line1: values.line1,
                line2: values.line2,
                state: values.state,
                city: values.city,
                pincode: values.pincode,
                
            }
            let address = {
                line1: defaultValue.line1,
                line2:defaultValue.line2,
                pincode: defaultValue.pincode,
                state: defaultValue.state,
                city: defaultValue.city
            }
            let gstDetails = {
                gstIn: defaultValue.gstIn,
                panNo: defaultValue.panNo,
                firmName: defaultValue.firmName,
                // websiteUrl: defaultValue.websiteUrl
            }
            const body = {
                name: values.name,
                email: values.email,
                phoneNo: values.phoneNo,
                hasGst: values.hasGst,
                gstDetails,
                address,
            }
            let response = await api.post(`supplier/${id}`, body)
            console.log("this is response", response)
            if (response.status === 200) {
                console.log(response)
                navigate("/supplier")

            }
        }
        catch (err) {
            console.error(err);
        }
    }

    return (
        <div className='addSupplier'>
            <div className='row justify-content-center'>
                <div className='col-md-12'>
                    <Card>
                        <CardContent>
                            {/* <h5 className='pageHeading'>Add Supplier</h5>
                            <Divider /> */}
                            <form onSubmit={handleSubmit}>
                                <div className='row mt-3'>
                                    <div className='col-md-4'>
                                        <TextField className='inputBox ms-1' id="companyFirmname" label="Company/ Firm Name" type="text" name="name" value={values.name} onChange={handleChange} variant="standard" />

                                        {<p className='form-error'>{errors.name}</p>}

                                    </div>
                                    <div className='col-md-4'>
                                        <TextField className='inputBox' id="email" label="Email" type="email" name="email" value={values.email} onChange={handleChange} variant="standard" />
                                        {<p className='form-error'>{errors.email}</p>}

                                    </div>
                                    <div className='col-md-4'>
                                        <TextField className='inputBox' id="phoneNo" label="Phone No" type="text" name="phoneNo" value={values.phoneNo} onChange={handleChange} variant="standard" />
                                        {<p className='form-error'>{errors.phoneNo}</p>}
                                    </div>
                                    <div className='col-md-4'>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox type="checkbox" checked={checked} value={values.hasGst} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} name='hasGst'
                                                onChange={(_e) => {
                                                    if (!checked) { 
                                                        values.hasGst = true
                                                        setText('')
                                                    }
                                                    setChecked(!checked)
                                                }
                                                } />} label="Have GST" />

                                             {/* {/ <FormControlLabel control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} name='hasGst' onChange={(e) => setHasGst(e.target.checked)} />} label="Have GST" />  /} */}
                                        </FormGroup>
                                    </div>
                                    <div className='col-md-4'>
                                    <TextField className='inputBox ms-1' id="firmName" name='firmName' type="text" disabled={!checked} value={checked?values.firmName:""} onChange={handleChange} label="Business Name" variant="standard" />
                                        {checked &&<p className='form-error'>{errors.firmName}</p>}
                                    </div>
                                    <div className='col-md-4'>

                                        <FormControl variant="standard" sx={{ m: 0, minWidth: '100%' }}>
                                            <InputLabel id="demo-simple-select-standard-label" disabled={!checked}>Business Type</InputLabel>
                                            <Select
                                                disabled={!checked}
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={age}
                                                onChange={handleSelectChange}
                                                label="age"
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                <MenuItem value={10}>Private Limited</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className='col-md-4'>
                                        <TextField className='inputBox' id="gstIn" label="GST Number" type="text" name="gstIn" value={checked?values.gstIn:""} onChange={handleChange} variant="standard" disabled={!checked} />
                                        {checked && <p className='form-error' >{errors.gstIn } </p>}
                                    </div>
                                    <div className='col-md-4'>
                                        <TextField className='inputBox' id="panNo" label="PAN Number" type="text" name="panNo" value={checked?values.panNo:""} onChange={handleChange} variant="standard" disabled={!checked} />
                                        {checked && <p className='form-error' >{errors.panNo}</p>}
                                      
                                    </div>
                                    {/* <div className='col-md-4'>
                                        <TextField className='inputBox' id="Currency" label="Currency" type="text" name="currency" value={values.currency} onChange={handleChange} variant="standard" disabled={!checked} />
                                    </div> */}
                                    {/* <div className='col-md-4'>
                                        <TextField className='inputBox' id="password" label="Password" type="text" name="password" value={values.password} onChange={handleChange} variant="standard" />
                                    </div>
                                    <div className='col-md-4'>
                                        <TextField className='inputBox' id="confirmPassword" label="Confirm Password" name="cnfPassword" type="text" value={values.cnfPassword} onChange={handleChange} variant="standard" />
                                    </div> */}
                                    <div className='row align-items-center'>
                                        <div className='col-md-6'>
                                            <h6 className='bold'>Address</h6>
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <TextField multiline minRows={1} maxRows={2} className='inputBox' id="block" label="Line1" type="text" name="line1" value={values.line1} onChange={handleChange} variant="standard" />
                                                    {<p className='form-error'>{errors.line1}</p>}
                                                </div>
                                                <div className='col-md-12'>
                                                    <TextField multiline minRows={1} maxRows={2} className='inputBox' id="block" label="Line2" type="text" name="line2" value={values.line2} onChange={handleChange} variant="standard" />
                                                    {<p className='form-error'>{errors.line1}</p>}
                                                </div>
                                                <div className="col-md-6">
                                                    <TextField className='inputBox' id="state" label="State" type="text" name="state" value={values.state} onChange={handleChange} variant="standard" />
                                                    {<p className='form-error'>{errors.state}</p>}
                                                </div>
                                                <div className="col-md-6">
                                                    <TextField className='inputBox' id="city" label="City" type="text" name="city" value={values.city} onChange={handleChange} variant="standard" />
                                                    {<p className='form-error'>{errors.city}</p>}
                                                </div>
                                                <div className="col-md-6">
                                                    <TextField className='inputBox' id="pincode" label="Pin Code" type="text" name="pincode" value={values.pincode} onChange={handleChange} variant="standard" />
                                                    {<p className='form-error'>{errors.pincode}</p>}
                                                </div>
                                            </div>
                                        </div> 
                                        <div className='col-md-6'>
                                            {/* {/ <h6 className='bold'>Address 2</h6> /} */}
                                            <div className='row'>
                                                {/* <div className='col-md-12'>
                                                    <TextField multiline minRows={2} maxRows={4} className='inputBox' id="block" label="Block/ street/ Appratment" type="text" name="line2" value={values.line2} onChange={handleChange} variant="standard" />
                                                </div>
                                                <div className="col-md-6">
                                                    <TextField className='inputBox' id="state" label="State" type="text" name="state2" value={values.state2} onChange={handleChange} variant="standard" />
                                                </div>
                                                <div className="col-md-6">
                                                    <TextField className='inputBox' id="city" label="City" type="text" name="city2" value={values.city2} onChange={handleChange} variant="standard" />
                                                </div>
                                                <div className="col-md-6">
                                                    <TextField className='inputBox' id="pincode" label="Pin Code" type="text" name="pinCode2" value={values.pinCode2} onChange={handleChange} variant="standard" />
                                                </div> */}
                                            </div>
                                        </div>
                                        {/* <div className='col-md-2'>
                                    <Link to="/">
                                        <Button className='addAddressBtn' variant="outlined" startIcon={<AddIcon />}>
                                            Add Address
                                        </Button>
                                    </Link>
                                </div> */}
                                    </div>
                                    {/* <div className="col-md-4">
                                    <TextField className='inputBox' type="date" id="DOB" label="DOB" variant="standard" />
                                </div>
                                <div className="col-md-4">
                                    <TextField className='inputBox' type="text" id="profession" label="Profession" variant="standard" />
                                </div>
                                <div className="col-md-4">
                                    <FormControl>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Marital Status</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                        >
                                            <FormControlLabel value="married" control={<Radio />} label="Married" />
                                            <FormControlLabel value="unmarried" control={<Radio />} label="Unmarried" />
                                        </RadioGroup>
                                    </FormControl>
                                </div> 
                                <div className="col-md-4">
                                    <FormControl>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                        >
                                            <FormControlLabel value="male" control={<Radio />} label="Male" />
                                            <FormControlLabel value="female" control={<Radio />} label="Female" />
                                        </RadioGroup>
                                    </FormControl>
                                </div>*/}
                                    <div className="col-md-4">
                                        <h2 className='sign'>Upload Signature</h2>
                                        <div className='signBox'>
                                            <IconButton color="primary" aria-label="upload picture" component="label">
                                                <input hidden accept="image/*" type="file" />
                                                <PhotoCamera />
                                            </IconButton>
                                            <img className='ms-5 img-fluid' src={Sign} alt="Sign" />
                                        </div>
                                    </div> 
                                </div>
                                <CardActions className='justify-content-center'>
                                    <Button className='loginBtn' variant='contained' type="submit">{id ? 'Update Supplier' : 'Add Supplier'}</Button>
                                </CardActions>
                            </form>
                        </CardContent>

                    </Card>

                </div>
            </div>
        </div >
    )
}
