import React from 'react'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

export default function Filter() {
    const [sorting, setSort] = React.useState('');

    const handleChange = (event) => {
        setSort(event.target.value);
    };
    
  return (
    <div>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
    <InputLabel id="demo-simple-select-standard-label">Sort By</InputLabel>
    <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={sorting}
        onChange={handleChange}
        label="Sort"
    >
        <MenuItem value="">
            <em>None</em>
        </MenuItem>
        <MenuItem value={10}>By Name</MenuItem>
        <MenuItem value={20}>PO Low to High</MenuItem>
        <MenuItem value={30}>PO High to Low</MenuItem>
    </Select>
</FormControl>
    </div>
  )
}
