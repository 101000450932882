import React, { useEffect, useState } from 'react'
import Api from '../../service/Api'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import './supplier.scss';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import SearchBar from '../common/search';
import Filter from '../common/filter';
import { useNavigate } from 'react-router-dom';
const api = new Api()

export default function Supplier() {
  const [supplierData, setSupplierData] = useState([]);
  const navigate = useNavigate();
  const fetchSupplier = async () => {
    let response = await api.get("supplier",);
    setSupplierData(response.data.data)
  }
  const deleteSupplier = async (row) => {
    let response = await api.delete(`supplier/${row._id}`);
    if (response) {
      fetchSupplier();
      console.log(response)
    }
  }

  const searchData = async (event) => {
    console.log(event)
    let key = event.target.value
    let response = await api.get('supplier', { searchName: key });
    if (response) {
      setSupplierData(response.data.data);
    }
  }

  useEffect(() => {
    fetchSupplier();

  }, [])

  function editSupplier(id) {
    console.log(id)
    navigate({
      pathname: `/add-supplier/${id}`
    })
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className='supplier'>
      <Card>
        <CardContent>
          <div className='supplierBox'>
            {/* <h3 className='heading'>Supplier List</h3> */}
            <Filter />
            <div className='iconBox'>
              <SearchBar searchData={searchData} />

              <Link to="/add-supplier">
                <Fab color="primary" aria-label="add" size="medium">
                  <AddIcon />
                </Fab>
              </Link>
            </div>
          </div>
          <TableContainer className='shadow-none' component={Paper}>
            <Table className='productData' sx={{ overflowX: 'scroll' }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Sr. No</TableCell>
                  <TableCell>Company/ Firm Name</TableCell>
                  <TableCell align="left">Supplier Address	</TableCell>
                  <TableCell align="left">GST No.</TableCell>
                  <TableCell align="left">Phone No.</TableCell>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {supplierData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                  <TableRow key={row._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="td" scope="col">
                      {index + 1}
                    </TableCell>

                    <TableCell align="left">
                      <p className='companyName'>{row.name.toUpperCase()}{row?.gstDetails ? `/${row?.gstDetails.firmName}` : "" }</p>
                    </TableCell>
                    <TableCell align="left">
                      {row.address.line1}{row.address.pincode}
                      {row.address.city}{row.address.state}
                    </TableCell>
                    <TableCell align="left">{row?.gstDetails?.hasGst || (row && row.gstDetails && row.gstDetails.gstIn) ? row.gstDetails.gstIn : '-'}</TableCell>
                    {/* <TableCell align="left">{row.gstDetails.gstIn && row.gstDetails.hasGst ? row.gstDetails.gstIn : "-" }</TableCell> */}
                    <TableCell align="left">{row.phoneNo}</TableCell>
                    <TableCell align="left">{row.email}</TableCell>

                    <TableCell align="center">
                      <div className='d-flex'>
                        <Button color='danger'>
                          <DeleteIcon onClick={() => deleteSupplier(row)} />
                        </Button>
                        <Button color='secondary'>
                          <EditIcon onClick={() => editSupplier(row._id)} />
                        </Button>

                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  {/* <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan="13"
                    count="2"
                    rowsPerPage="3"
                    page="10"

                    onPageChange="2"
                  /> */}
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={supplierData.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </CardContent>
      </Card>
    </div>
  )
}

