import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Link } from "react-router-dom";
import './grn.scss'
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import TablePagination from '@mui/material/TablePagination';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import Api from '../../service/Api';
import Filter from '../common/filter';
import SearchBar from '../common/search';
import logo from '../../assets/image/logo.png'
import { Box } from '@mui/material';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import moment from "moment";

const api = new Api()

export default function Grn() {
    const [grnData, setGrnData] = useState([]);
    const [GRNDownload, setGRNDownload] = useState({});
    const [profile, setProfile] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        getGrn();
        getProfile();
    }, [])

    const getProfile = async () => {
        let response = await api.get("profile",);
        setProfile(response.data.data)
    }


    const getGrn = async () => {
        let response = await api.get('grn');
        if (response) {
            setGrnData(response.data.data)
        }
    }

    const searchData = async (event) => {
        let response = await api.get('grn', { searchName: event.target.value });
        if (response) {
            setGrnData(response.data.data);
        }
    }
    const getGrnDetails = async (grnId) => {
        let response = await api.get(`grn/${grnId}`);
        if (response) {
            setGRNDownload(response.data.data);
        }
    }

    const generatePDF = () => {
        html2canvas(document.getElementById("capture"), {
            onclone: function (clonedDoc) {
                clonedDoc.getElementById('capture').style.display = 'block';
            }
        })
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF({
                    // orientation: 'landscape',
                });
                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                pdf.save(moment(GRNDownload.createdAt).utc().format('YYYY-MM-DD') + '_GRN' + '_' + GRNDownload.poNumber + '.pdf');
                setGRNDownload({});
            });


    };

    const handlePDFGenerator = async (grnId) => {
        await getGrnDetails(grnId);
        if (GRNDownload != null && GRNDownload.products) {
            generatePDF();
        }
    };
    return (
        <div>
            <Card className='clientCard'>
                <CardContent>
                    <div className='clientBox'>
                        <div className='iconBox'>
                            <Filter />
                            <SearchBar searchData={searchData} />
                        </div>
                        <Link to="/create-grn">
                            <Fab color="primary" aria-label="add" size="medium">
                                <AddIcon />
                            </Fab>
                        </Link>
                    </div>
                    <TableContainer className='shadow-none' component={Paper}>
                        <Table sx={{ minWidth: 650, overflowX: 'auto' }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>P.O Number</TableCell>
                                    <TableCell align="left">Date</TableCell>
                                    <TableCell align="left">Supplier Name</TableCell>
                                    <TableCell align="left">Items</TableCell>
                                    <TableCell align="left">Amount</TableCell>
                                    <TableCell align="left">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                    ? grnData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : grnData
                                ).map((row) => (
                                    <TableRow key={row._id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="left">
                                            {row.poNumber}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row?.createdAt ? new Date(row.createdAt).toDateString() : '-'}
                                        </TableCell>
                                        <TableCell align="left">{row?.supplierData?.name}</TableCell>
                                        <TableCell align="left">{row?.totalReceivedQuantity}</TableCell>
                                        <TableCell align="left">{row?.totalReceivedAmount}</TableCell>
                                        <TableCell align="left">
                                            <Button>
                                                <FileDownloadOutlinedIcon onClick={() => handlePDFGenerator(row._id)} />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        component="div"
                        count={grnData?.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </CardContent>
            </Card>
            <div id="capture" >
                <div className='container invoicePdf' >
                    <div className='row py-3'>
                        <div className='col-sm-3'>
                            <img src={logo} className='logo' />
                        </div>
                        <div className='col-sm-3' ></div>
                        <div className='col-sm-4'></div>
                    </div>
                    <Box sx={{ overflow: "auto" }}>
                        <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table" style={{ borderBottom: 'transparent' }}>
                                <TableBody>
                                    <TableRow className='billingAddresses' >
                                        <TableCell colSpan={6}>
                                            {(profile && profile.userProfile && profile.userProfile.firmDetails) &&
                                                <div>
                                                    <p><span>Sold By :</span></p>
                                                    <p>{profile.userProfile.firmDetails.firmName}</p>
                                                    <p>{profile.userProfile.address.line1}</p>
                                                    <p>{profile.userProfile.address.line2}</p>
                                                    <p><span>PAN No.</span>{profile.userProfile.firmDetails.panNo}</p>
                                                    <p><span>GST Registration No.:</span> {profile.userProfile.firmDetails.gstIn}</p>
                                                </div>
                                            }
                                        </TableCell>
                                        <TableCell colSpan={6} align="right" className='billingSection'>
                                            {grnData &&
                                                <div  >
                                                    <p><span>Billing Address:</span></p>
                                                    <p>{GRNDownload?.supplierData?.name}</p>
                                                    <p><span>Mobile No:</span>{GRNDownload?.supplierData?.phoneNo}</p>
                                                    <p><span>Email Id:</span>{GRNDownload?.supplierData?.email}</p>
                                                </div>}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box></Box>
                    <div className='mt-0' >
                        <Box sx={{ overflow: "auto" }}>
                            <Box sx={{ width: "90%", display: "table" }}>
                                <Table className='shadow-none productTable' style={{ borderTop: '1px solid rgba(205, 201, 201, 0.588)', borderBottom: '1px solid rgba(205, 201, 201, 0.588)' }}>
                                    <TableHead>
                                        <TableRow >
                                            <TableCell align="left" colSpan={1} className='TableBrder__tableHead'>Sr. No.</TableCell>
                                            <TableCell align="left" colSpan={1} className='TableBrder__tableHead'>Description</TableCell>
                                            <TableCell align="left" colSpan={1} className='TableBrder__tableHead'>Barcode</TableCell>
                                            <TableCell align="left" colSpan={1} className='TableBrder__tableHead'>Unit Price</TableCell>
                                            <TableCell align="left" colSpan={1} className='TableBrder__tableHead'>HSN</TableCell>
                                            <TableCell align="left" colSpan={1} className='TableBrder__tableHead'>SGST</TableCell>
                                            <TableCell align="left" colSpan={1} className='TableBrder__tableHead'>CGST</TableCell>
                                            <TableCell align="left" colSpan={1} className='TableBrder__tableHead'>IGST</TableCell>
                                            <TableCell align="left" colSpan={1} className='TableBrder__tableHead'>Tax Amt</TableCell>
                                            <TableCell align="left" colSpan={1} className='TableBrder__tableHead'>Total Amt</TableCell>
                                            <TableCell align="left" colSpan={1} className='TableBrder__tableHead'>GRN Qty</TableCell>
                                            <TableCell align="left" colSpan={1} className='TableBrder__tableHead'>Total Qty Received</TableCell>
                                            <TableCell align="left" colSpan={1} className='TableBrder__tableHead'>Qty Ordered</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody >
                                        {(GRNDownload.products ? GRNDownload.products : []).map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{item && item.name ? item.name : "xyz"}</TableCell>
                                                <TableCell>{item && item.barcode ? item.barcode : 0}</TableCell>
                                                <TableCell>{item && item.purchasePrice}</TableCell>
                                                <TableCell>{item && item.hsn ? item.hsn : 0}</TableCell>
                                                <TableCell>{item && item.gst ? item.gst / 2 : 0}</TableCell>
                                                <TableCell>{item && item.gst ? item.gst / 2 : 0}</TableCell>
                                                <TableCell>0</TableCell>
                                                <TableCell>{item && item.purchasePrice ? ((item.purchasePrice * item.grnQuantity * item.gst) / 100).toFixed(2) : 0}</TableCell>
                                                <TableCell>{item && item.purchasePrice ? ((item.purchasePrice * item.grnQuantity)).toFixed(2) : 0}</TableCell>
                                                <TableCell>{item && item.grnQuantity ? item.grnQuantity : null}</TableCell>
                                                <TableCell>{item && item.receivedQuantity ? item.receivedQuantity : null}</TableCell>
                                                <TableCell>{item && item.purchaseQuantity ? item.purchaseQuantity : null}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>

                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className='footprice' colSpan={10} align="right" >Total Items: {GRNDownload.products ? GRNDownload.totalReceivedQuantity : 0}</TableCell>
                                            <TableCell className='footprice' colSpan={1} align="right">Grand Total:<CurrencyRupeeOutlinedIcon fontSize='small' />{GRNDownload?.totalReceivedAmount}</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Box>
                        </Box>
                    </div>
                </div>
            </div>
        </div>
    )
}
