import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import '../createInvoice/createInvoice.scss'
import Button from '@mui/material/Button';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import TextField from '@mui/material/TextField';
import TableFooter from '@mui/material/TableFooter';
import CardActions from '@mui/material/CardActions';
import DeleteIcon from '@mui/icons-material/Delete';
import { Autocomplete, Alert, Snackbar } from '@mui/material';
import Api from '../../service/Api';
import InfoInput from '../common/infoInput'

export default function CreateInvoice() {
  const api = new Api();
  const navigate = useNavigate();
  const [client, setClient] = useState(null);
  const [clientList, setClientList] = useState([]);
  const [profile, setProfile] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  // const [supplierList, setSupplierList] = useState([]);
  const handleClientNameChange = async (event, value, reason) => {
    if (reason === 'input') {
      if (event.target.value === "") {
        setClientList([])
        setSelectedClient(null);
      }
      else {
        let response = await api.get("client", { searchName: event.target.value });
        if (response.status == 200) {
          setClientList(response.data.data);
        }
      }
    }
  }
  const setClientName = (e, option, reason) => {
    if (reason === 'selectOption') {
      setSelectedClient(option);
    }
  }
  /* eslint-disable default-case */
  const [totalOrderedAmount, setTotalOrderedAmount] = useState()
  const [productData, setProductData] = useState([]);
  /* eslint-enable default-case */
  const [productList, setProductList] = useState([]);
  // const [isSearchProduct, setIsSearchProduct] = useState(false);
  const [clear, setClear] = useState(null);
  const [snackbarObj, setSnackbarObj] = useState({ isShowSnackbar: false, message: '', variant: '' });

  const getClientList = async () => {
    let response = await api.get("client");
    setClientList(response.data.data)
  }

  const getClient = async (id) => {
    let response = await api.get("client", { clientId: id });
    setClient(response.data.data[0])
  }

  const getProfile = async () => {
    let response = await api.get("profile",);
    setProfile(response.data.data)
  }

  const handleValidation = () => {
    if (!(productData && productData.length > 0)) {
      setSnackbarObj({
        isShowSnackbar: true,
        message: 'Product are empty',
        variant: 'warning'
      })
      return false;
    }
    if (!(selectedClient)) {
      setSnackbarObj({
        isShowSnackbar: true,
        message: 'Client not selected',
        variant: 'warning'
      })
      return false;
    }
    let emptyQuan = false;
    for (var i = 0; i < productData.length; i++) {
      if (!productData[i].quantity) {
        emptyQuan = true;
        break
      }
    }
    if (emptyQuan) {
      setSnackbarObj({
        isShowSnackbar: true,
        message: 'Please fill all product detail',
        variant: 'warning'
      })
      return false;
    }
    return true
  }

  const submitInvoiceData = async () => {
    try {
      if (handleValidation()) {
        let products = productData.map(el => {
          return {
            productId: el._id,
            quantity: el.quantity,
            gst: el.gst,
            sellPrice: el.sellPrice
          }
        });

        let body = {
          products: products,
          clientId: selectedClient._id
        }
        let response = await api.put('invoice', body)
        if (response.status == 200) {
          navigate('/sales')
        } else {
          setSnackbarObj({isShowSnackbar: true, variant: 'error', message: response.message});
        }
      }
    } catch (err) {
      console.error(err);
      setSnackbarObj({isShowSnackbar: true, variant: 'error', message: err?.response?.data?.message || 'Something Went Wrong'});
    }
  }

  const checkProductAvailability = async (id) => {
    try {
      const response = await api.get(`inventory/${id}`);
      if (response.status === 200) {
        return response?.data;
      }
      return false;
    } catch (err) {
      console.error(err);
      return false;
    }
  }


  const setProductName = async (e, option, reason) => {
    if (reason === 'selectOption') {
      const isCheck =  await checkProductAvailability(option._id);
      if (isCheck?.isProductAvailable) {
        option['quantity'] = 1;
        option['availableQty'] = isCheck?.availableQuantity;
        option['netAmount'] = option.unitPrice;
        // option['taxAmount'] = (option.unitPrice) * (option.gst / 100);
        option['taxAmount']= (option.unitPrice * option.quantity * option.gst) / 100;
        option['totalAmount'] = option.taxAmount + option.unitPrice;
        setProductData([...productData, option])
        setClear(null);
        // setIsSearchProduct(false);
      } else {
        setSnackbarObj({isShowSnackbar: true, variant: 'warning', message: "This product is not available in the inventory"});
      }
    }
  }

  const handleTotal = () => {
    let total = 0;
    productData.map(el => {
      total += el.totalAmount
    })
    return total ? total.toFixed(2) : total;
  }

  const handleProductNameChange = async (event, value, reason) => {
    if (reason === 'input') {
      if (event.target.value === "") {
        // setName(event.target.value);
        setProductList([])
      }
      else {
        let response = await api.get("product", { searchName: event.target.value })
        if (response) {
          setProductList(response.data.data)
        }
      }
    }
  }

  const handleQtyChange = (e, row) => {
    row.quantity = parseInt(e.target.value) || 0;
    row.netAmount = row.quantity * row.unitPrice;
    row.taxAmount = row.quantity * ((row.unitPrice) * (row.gst / 100));
    let arr = productData.map(el =>
      el._id === row._id ? ({
        ...el,
        quantity: row.quantity,
        netAmount: row.netAmount,
        taxAmount: row.taxAmount,
        totalAmount: row.taxAmount + row.netAmount
      }) : el);
    setProductData(arr)
  }
  const handleDelete = (index, e) => {
    setProductData(productData.filter((item, i) => i !== index));
  };

  useEffect(() => {
    getClientList();
    getProfile();
    setTotalOrderedAmount(handleTotal)
  }, [totalOrderedAmount])
  return (
    <div>
      <Card className='createInvoiceCard'>
        <CardContent>
          <div className='billingAddresses'>
            <InfoInput
              nameList={clientList}
              selectedName={selectedClient}
              onSelectOption={setClientName}
              onInputChange={handleClientNameChange}
            />
            {/* <InfoInput/> */}
          </div>

          <div style={{ display: 'flex', justifyContent: 'flex-end' }} className='mt-0'>
            <TableCell align="left">
              <Autocomplete
                freeSolo
                disablePortal
                id="searchproduct"
                name="searchproduct"
                value={clear}
                isOptionEqualToValue={(option, newValue) => {
                  return option._id === newValue._id;
                }}
                filterOptions={(x) => x}
                getOptionLabel={(option) => `${option.name} - ${option.unitPrice}`}
                options={productList}
                onChange={(e, option, reason) => setProductName(e, option, reason)}
                onInputChange={(e, value, reason) => handleProductNameChange(e, value, reason)}
                sx={{ width: 200 }}
                renderInput={(params) => <TextField {...params} label="Select Products" />}
              />

            </TableCell>
          </div>
          <TableContainer className='shadow-none' component={Paper}>
            <Table sx={{ minWidth: 650, overflowX: 'auto' }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Sr. NO</TableCell>
                  <TableCell align="left">Description</TableCell>
                  <TableCell align="left">Unit Price</TableCell>
                  <TableCell align="left">Qty</TableCell>
                  <TableCell align="left">Available Qty</TableCell>
                  <TableCell align="left">Net Amount</TableCell>
                  <TableCell align="left">HSN</TableCell>
                  <TableCell align="left">SGST</TableCell>
                  <TableCell align="left">CGST</TableCell>
                  <TableCell align="left">IGST</TableCell>
                  <TableCell align="left">Tax Amount</TableCell>
                  <TableCell align="left">Total Amount</TableCell>
                  <TableCell align="left">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {((productData || []).map((row, i) => (
                  <TableRow key={`${row._id} - ${i}`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="left">
                      {i + 1}
                    </TableCell>
                    <TableCell align="left">
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{row.unitPrice}</TableCell>
                    <TableCell align="left"><TextField variant="standard" defaultValue={row.quantity} name="quantity" onChange={(event) => handleQtyChange(event, row)} /><span className='qtyLeft'>{row.availableQty < 10 ?`Qty left ${row.availableQty}` : ""}</span></TableCell>
                    <TableCell align="left">{row.availableQty}</TableCell>
                    <TableCell align="left">{row.netAmount}</TableCell>
                    <TableCell align="left">{row.hsn ? row.hsn : "-"}</TableCell>
                    <TableCell align="left">{row.gst / 2}</TableCell>
                    <TableCell align="left">{row.gst / 2}</TableCell>
                    <TableCell align="left">0</TableCell>
                    <TableCell align="left">{row.taxAmount.toFixed(2)}</TableCell>
                    <TableCell align="left">{row.totalAmount.toFixed(2)}</TableCell>

                    <TableCell align="center">
                      <div className='d-flex justify-content-center'>
                        <Button color='danger' onClick={(e) => handleDelete(i, e)}>
                          <DeleteIcon />
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                )))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={9} align="right">
                    <p className='footprice'>Total Items: {productData.length}</p>
                  </TableCell>
                  <TableCell colSpan={6} align="left">
                    <p className='footprice'>Grand Total:  <CurrencyRupeeIcon />{handleTotal()}</p>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </CardContent>
        <CardActions className='cardActions justify-content-center'>
          <Button className='submitBtn' variant='contained' onClick={() => submitInvoiceData()}>Submit</Button>
        </CardActions>
      </Card>
      {snackbarObj.isShowSnackbar && (
        <Snackbar open={snackbarObj.isShowSnackbar} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() => setSnackbarObj({ isShowSnackbar: false, message: '', variant: '' })}>
          <Alert onClose={() => setSnackbarObj({ isShowSnackbar: false, message: '', variant: '' })} severity={snackbarObj.variant} sx={{ width: '100%' }}>
            {snackbarObj.message}
          </Alert>
        </Snackbar>)}
    </div>
  )
}
