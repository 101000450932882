/* eslint-disable react/prop-types */
import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Logo from '../../../assets/image/logo.png';
import './styles.scss';
 
const InvoicePdf = ({profile, client, productData, grandTotal}) => {

  return (
    <div className="invoicePdf">
      <div className='row mx-auto mb-2'>
          <img className='logo' src={Logo} alt="Logo" />
      </div>
      <div className='billingAddresses'>
        <TableContainer className='shadow-none' component={Paper}>
          <Table sx={{ minWidth: 650, overflowX: 'auto' }} aria-label="simple table">

            <TableBody>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell colSpan={6} align="left">
                  {
                    (profile && Object.keys(profile).length) ? (
                      <div>
                        <h2>Sold By :</h2>
                        <p>{profile?.userProfile?.firmDetails?.firmName}</p>
                        <p>
                          {profile.userProfile.address.line1} {profile.userProfile.address.line2} {profile.userProfile.address.city.toUpperCase()} {profile.userProfile.address.state.toUpperCase()} {profile.userProfile.address.country.toUpperCase()} {profile.userProfile.address.pincode}
                        </p>
                        <p><span>PAN No: </span>AAXFK29k12</p>
                        <p><span>GST Registration No: </span>09AAXFK2921K1ZQ</p>
                      </div>
                    )
                    : null
                  }
                </TableCell>
                <TableCell colSpan={6} align="right">
                  <div className='billingSection'>
                    <h2>Billing Address :</h2>
                    {
                      (client && Object.keys(client).length) ? (
                        <div>
                          <p>{client.name}</p>
                          <p><span>Mobile No: </span>{client.phoneNo}</p>
                          <p><span>Email No: </span>{client.email}</p>
                          <p>
                            {client.address.line1} {client.address.line2} {client.address.city.toUpperCase()} {client.address.state.toUpperCase()} {client.address.country.toUpperCase()} {client.address.pincode}
                          </p>
                          {client.gstDetails && <p><span>PAN No: </span>{client.gstDetails.panNo}</p>}
                          {client.gstDetails && <p><span>GST Registration No: </span>{client.gstDetails.gstIn}</p>}
                        </div>
                      )
                      : null
                    }
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <TableContainer className='shadow-none productTable' component={Paper}>
        <Table sx={{ minWidth: 650, overflowX: 'auto' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Sr. NO</TableCell>
              <TableCell align="left">Description</TableCell>
              <TableCell align="left">Unit Price</TableCell>
              <TableCell align="left">Qty</TableCell>
              <TableCell align="left">Net Amount</TableCell>
              <TableCell align="left">HSN</TableCell>
              <TableCell align="left">SGST</TableCell>
              <TableCell align="left">CGST</TableCell>
              <TableCell align="left">IGST</TableCell>
              <TableCell align="left">Tax Amount</TableCell>
              <TableCell align="left">Total Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {((productData || []).map((row, i) => (
                  <TableRow key={`${row._id} - ${i}`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="left">
                      {i + 1}
                    </TableCell>
                    <TableCell align="left">
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{row.unitPrice}</TableCell>
                    <TableCell align="left">{row.quantity}</TableCell>
                    <TableCell align="left">{row.netAmount}</TableCell>
                    <TableCell align="left">{row.hsn ? row.hsn : "-"}</TableCell>
                    <TableCell align="left">{row.gst/2}</TableCell>
                    <TableCell align="left">{row.gst/2}</TableCell>
                    <TableCell align="left">0</TableCell>
                    <TableCell align="left">{(row.taxAmount || 0).toFixed(2)}</TableCell>
                    <TableCell align="left">{(row.totalAmount || 0).toFixed(2)}</TableCell>
                  </TableRow>
                )
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={9} align="right">
                <p className='footprice'>Total Items: {(productData || []).length}</p>
              </TableCell>
              <TableCell colSpan={6} align="left">
                <p className='footprice'>Grand Total: {(grandTotal || 0).toFixed(2)}</p>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}
 
export default InvoicePdf;