import React from 'react'
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Loginschema } from '../schema/Loginschema';
import { Link } from "react-router-dom";
import { login } from "../../service/Auth";
import Logo from '../../assets/image/logo.png';
import { useFormik } from 'formik';
import './customers.scss'


const initialValues = {
  email: "",
  password: "",

};
export default function CustomersLogin() {
  const navigate = useNavigate();
  const handleLogin = async ()=>{
    const data = {
      email:values.email,
      password:values.password
    }
    const success =  await login(data);
    if(success){
      navigate("/dashboard");
    } 
  }
  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: Loginschema,
    // eslint-disable-next-line no-unused-vars
    onSubmit: (values, action) => {
      console.log(values)
      handleLogin();
    }

  })


  return (
    <div className='login'>
      <div className='row justify-content-center align-items-center h-100'>
        <div className='col-md-4'>
          <Card className='loginCard'>
            <CardContent>
              <img className='loginLogo' src={Logo} alt='logo' />
              <h3 className='loginHeading'>Login to yours Account</h3>
              <form onSubmit={handleSubmit}>

                <div className='row '>
                  <div className='col-md-12'>
                    <TextField className='inputBox' id="email" label="Email" variant="standard" type='email' name="email" value={values.email} onChange={handleChange} />
                    {errors.email && touched.email ? <p className='form-error'>{errors.email}</p> : null}

                  </div>
                  <div className='col-md-12'>
                    <TextField className='inputBox' id="password" label="Password" variant="standard" type='password' name="password" value={values.password} onChange={handleChange} />
                    {errors.password && touched.password ? <p className='form-error'>{errors.password}</p> : null}

                  </div>
                  <div className='col-md-5'>
                    <Link to="/forgot-password">
                      <p>Forgot Password</p>
                    </Link>
                  </div>
                  <div className='col-md-7'>
                    <p className='text-end'>Do not have account?
                      <Link className='ms-1' to="/signup">
                        Sign Up
                      </Link>
                    </p>
                  </div>
                </div>
                <CardActions className='justify-content-center'>
                  <Button className='loginBtn' variant='contained' type="submit">Login</Button>
                </CardActions>
              </form>
            </CardContent>

          </Card>
        </div>
      </div>
    </div>
  )
}
