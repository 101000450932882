import * as Yup from 'yup';


const regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
const reggst = /^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/;
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const AddclientSchema = Yup.object().shape({
    name: Yup.string().min(2).max(25).required("Please Enter Your Name"),
    email: Yup.string().email().required("Please Enter Your email"),
    phoneNo: Yup.string().matches(phoneRegExp, 'Phone number must have Ten numbers'),
    line1: Yup.string().required("Please enter address"),
    line2: Yup.string().required("Please enter address"),
    state: Yup.string().required("Enter State Name"),
    city: Yup.string().required("Enter City Name"),
    country: Yup.string().required("Enter Country Name"),
    pincode: Yup.string().required("Please enter Pin code"),
    hasGst: Yup.boolean(),
}).test('conditional-validation', null, function (values) {
    const { hasGst, gstIn, panNo, firmName, websiteUrl } = values;

    let errors = {};

    if (hasGst) {
        if (!gstIn) {
            errors.gstIn = "Please enter GST number";
        }
        if (!panNo) {
            errors.panNo = "Please enter PAN number";
        }
        if (!firmName) {
            errors.firmName = "Please enter Firm Name";
        }
        if (!websiteUrl) {
            errors.websiteUrl = "Please enter Url";
        }
    }

    return Object.keys(errors).length ? this.createError({ path: '', errors }) : true;
});