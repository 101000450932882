/* eslint-disable react/react-in-jsx-scope */
import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import profilePic from '../assets/image/profile.png'
import Logo from '../assets/image/logo.png'
import './template.scss';
import '../style/variables.scss';
import { Link, useNavigate } from "react-router-dom";
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { logout } from '../service/Auth';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import SellRoundedIcon from '@mui/icons-material/SellRounded';
import LogoutIcon from '@mui/icons-material/Logout';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import { NavLink, } from "react-router-dom";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Badge, Menu, MenuItem, Tooltip } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import Logout from '@mui/icons-material/Logout';
import { PersonAdd, Settings } from '@mui/icons-material';
const drawerWidth = 240;

function Header(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = useState(false);
    const navigate = useNavigate();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleLogout = () => {
        logout();
        navigate("/login");
    }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const drawer = (
        <div className='header'>
            <Toolbar className='ps-0'>
                <List >
                    <ListItem alignItems="center">
                        <ListItemAvatar>
                            <Avatar alt="Remy Sharp" src={profilePic} />
                        </ListItemAvatar>
                        <ListItemText
                            primary="Nishant Patel"
                        />
                    </ListItem>
                </List>
            </Toolbar>
            <Divider />

            <NavLink
                to="/sles"
                className={({ isActive, isPending }) =>
                    isActive
                        ? "active"
                        : isPending
                            ? "pending"
                            : ""
                }
            >
                <List >
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <DashboardIcon />
                            </ListItemIcon>
                            <ListItemText primary="Dashboard" />
                        </ListItemButton>
                    </ListItem>

                </List>
            </NavLink>
            <NavLink
                to="/sales"
                className={({ isActive, isPending }) =>
                    isActive
                        ? "active"
                        : isPending
                            ? "pending"
                            : ""
                }
            >
                <List >
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <LoyaltyIcon />
                            </ListItemIcon>
                            <ListItemText primary="Sale" />
                        </ListItemButton>
                    </ListItem>

                </List>
            </NavLink>

            <Accordion className='pbAccordion' sx={{ boxShadow: 'none', border: 'none' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <StoreOutlinedIcon />
                    <Typography className='pageName'>Purchase</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <NavLink
                        to="/purchase-list"
                        className={({ isActive, isPending }) =>
                            isActive
                                ? "active"
                                : isPending
                                    ? "pending"
                                    : ""
                        }
                    >
                        <List >
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <LoyaltyIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Purchase Order" />
                                </ListItemButton>
                            </ListItem>

                        </List>
                    </NavLink>

                    <Link to="create-direct-purchase">
                        <List >
                            <ListItem disablePadding>

                                {/* <ShoppingBasketOutlinedIcon/> */}
                                <ListItemButton>
                                    <ListItemIcon>
                                        <ShoppingBasketOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Direct Purchase" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Link>
                    <Link to="grn">
                        <List >
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <ReceiptLongOutlinedIcon sx={{ minWidth: 32 }} />
                                    </ListItemIcon>
                                    <ListItemText primary="GRN" />
                                </ListItemButton>
                            </ListItem>

                        </List>
                    </Link>
                </AccordionDetails>
            </Accordion>
            <Link to="/product">
                <List >
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <Inventory2OutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Product" />
                        </ListItemButton>
                    </ListItem>

                </List>
            </Link>
            <Link to="/Inventory">
                <List >
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <InventoryOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Inventory" />
                        </ListItemButton>
                    </ListItem>

                </List>
            </Link>
            <Link to="/customers" activeclassname="selected">
                <List >
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <PeopleOutlineIcon />
                            </ListItemIcon>
                            <ListItemText primary="Customers" />
                        </ListItemButton>
                    </ListItem>

                </List>
            </Link>
            <Link to="/supplier">
                <List >
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <ManageAccountsOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Supplier" />
                        </ListItemButton>
                    </ListItem>

                </List>
            </Link>
            <Link to="/employee">
                <List >
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <PersonAddAltRoundedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Employee" />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Link>
            <Link to="/task">
                <List >
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <TaskAltIcon />
                            </ListItemIcon>
                            <ListItemText primary="Task" />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Link>
            <Link to="/expense">
                <List >
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <SellRoundedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Expense" />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Link>
            <Link to="/purchase/expense">
                <List >
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <SellRoundedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Purchase Expense" />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Link>
            <Accordion className='pbAccordion' sx={{ boxShadow: 'none', border: 'none' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <SettingsOutlinedIcon />
                    <Typography className='pageName'>Setting</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Link to="setting">
                        <List >
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <ManageAccountsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Account Info" />
                                </ListItemButton>
                            </ListItem>

                        </List>
                    </Link>
                    <Link to="setting">
                        <List >
                            <ListItem disablePadding onClick={handleLogout}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <LogoutIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Logout" />
                                </ListItemButton>
                            </ListItem>

                        </List>
                    </Link>

                </AccordionDetails>
            </Accordion>



        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box className='header' sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                color="white"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar className='w-100'>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <div className='d-flex justify-content-between w-100 align-items-center'>
                        <Typography variant="h6" noWrap component="div">
                            <img className='logo' src={Logo} alt="Logo" />
                        </Typography>
                        <div className='d-flex'>
                            <React.Fragment>
                                <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>

                                    <Tooltip title="Account settings">
                                        <IconButton

                                            onClick={handleClick}
                                            size="small"
                                            sx={{ ml: 0 }}
                                            color="primary"
                                            aria-controls={open ? 'account-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                        >
                                            <Avatar
                                                color="primary"
                                                sx={{ width: 32, height: 32, backgroundColor: "#000" }}>N</Avatar>
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                                <Menu
                                    anchorEl={anchorEl}
                                    id="account-menu"
                                    open={open}
                                    onClose={handleClose}
                                    onClick={handleClose}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            '& .MuiAvatar-root': {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                            },
                                            '&::before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgcolor: 'background.paper',
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                            },
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >

                                    <Link to="setting">
                                        <Tooltip title="Account Info" placement='left'>
                                            <MenuItem className='py-3' onClick={handleClose}>
                                                <Avatar /> Nishant Patel
                                            </MenuItem>
                                        </Tooltip>
                                    </Link>
                                    <Divider className='m-0' />

                                    <MenuItem className='py-3' onClick={handleLogout}>
                                        <ListItemIcon>
                                            <Logout fontSize="small" />
                                        </ListItemIcon>
                                        Logout
                                    </MenuItem>
                                </Menu>
                            </React.Fragment>

                            {/* <Tooltip title="Notifications">
                                <IconButton size="large" aria-label="show 4 new mails" color="secondary">
                                    <Badge badgeContent={4} color="error">
                                        <NotificationsIcon />
                                    </Badge>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Wallet">
                                <IconButton size="large" aria-label="show 4 new mails" color="secondary"><AccountBalanceWalletIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Logout">
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="primary-search-account-menu"
                                    aria-haspopup="true"
                                    color="secondary"
                                    onClick={handleLogout}
                                >
                                    <LogoutIcon />
                                </IconButton>
                            </Tooltip> */}
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
            </Box>
        </Box>
    );
}

Header.propTypes = {
    window: PropTypes.func,
};

export default Header;

