import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import TextField from '@mui/material/TextField';
import CardActions from '@mui/material/CardActions';
import DeleteIcon from '@mui/icons-material/Delete';
import { Autocomplete, Alert, Snackbar, FormGroup } from '@mui/material';
import Form from 'react-bootstrap/Form';
import Api from '../../service/Api';

const api = new Api();
const emptyProduct = {
  "description": "",
  "unitPrice": 0,
  "quantity": 0,
  "hsn": "",
  "sgst": 0,
  "cgst": 0,
  "igst": 0,
  "billDate": null,
};
export default function CreateDirectPurchase() {
  const [productData, setProductData] = useState([{...emptyProduct}]);
  const [billDate, setBillDate] = useState(null);
  const [billDoc, setBillDoc] = useState(null);
  const [billNumber, setBillNumber] = useState(null);
  const [supplierList, setSupplierList] = useState([]);
  const [supplier, setSupplier] = useState({})
  const [snackbarObj, setSnackbarObj] = useState({ isShowSnackbar: false, message: '', variant: '' });


  const handleSupplierNameChange = async (event, reason) => {
    if (reason === 'input') {
      setSupplier(prevState => ({
        ...prevState,
        [event.target.id]: event.target.value
      }))
      if (event.target.value === "") {
        setSupplierList([])
        setSupplier({});
      }
      else {
        let response = await api.get("supplier", { searchName: event.target.value });
        if (response.status == 200) {
          setSupplierList(response.data.data);
        }
      }
    }
  }

  const setSupplierName = (option, reason) => {
    if (reason === 'selectOption') {
      let supplier = {
        "_id": option._id,
        "name": option.name,
        "businessName": option.gstDetails?.firmName,
        "contactNo": option.phoneNo,
        "emailId": option.email,
        "addressLine1": option.address.line1,
        "addressLine2": option.address.line2,
        "pincode": option.address.pincode,
        "city": option.address.city,
        "state": option.address.state,
        "country": option.address?.country,
        "panNo": option.gstDetails?.panNo,
        "gstNo": option.gstDetails?.gstIn,
        "websiteUrl": option.gstDetails?.websiteUrl,
      }
      setSupplier(supplier);
    }
  }

  const handleValidation = () => {
    if (!(productData && productData.length > 0)) {
      setSnackbarObj({
        isShowSnackbar: true,
        message: 'Product are empty',
        variant: 'warning'
      })
      return false;
    }
    let emptyQuan = false;
    for (var i = 0; i < productData.length; i++) {
      if (!productData[i].quantity) {
        emptyQuan = true;
        break
      }
    }
    if (emptyQuan) {
      setSnackbarObj({
        isShowSnackbar: true,
        message: 'Please fill all product detail',
        variant: 'warning'
      })
      return false;
    }
    return true
  }

  const addSupplier = async () => {
    try {
      if (supplier._id) {
        return supplier._id;
      }
      const defaultValue = {
        name: supplier.name,
        email: supplier.emailId,
        phoneNo: supplier.contactNo,
        firmName: supplier.businessName,
        gstIn: supplier.gstNo,
        panNo: supplier.panNo,
        line1: supplier.addressLine1,
        line2: supplier.addressLine2,
        state: supplier.state,
        city: supplier.city,
        pincode: supplier.pincode,
        hasGst: supplier.hasGst,
        websiteUrl: supplier.websiteUrl,
        country: supplier.country
      }

      let address = {
        line1: defaultValue.line1,
        line2: defaultValue.line2,
        pincode: defaultValue.pincode,
        state: defaultValue.state,
        city: defaultValue.city,
        country: defaultValue.country
      }
      let gstDetails = {
        firmName: defaultValue.firmName,
        gstIn: defaultValue.gstIn,
        panNo: defaultValue.panNo,
        websiteUrl: defaultValue.websiteUrl
      }

      const body = {
        name: defaultValue.name,
        email: defaultValue.email,
        phoneNo: defaultValue.phoneNo,
        hasGst: Boolean(defaultValue.firmName || defaultValue.gstIn || defaultValue.panNo),
        gstDetails,
        address,
      }
      let response = await api.put('supplier', body)
      return response.data.data._id;
    }

    catch (err) {
      console.error(err);
      return null;
    }
  }


  const submitPurchaseData = async () => {
    if (handleValidation()) {
      let products = productData.map(el => {
        return {
          ...el,
          taxAmount: (el.quantity * el.unitPrice * (Number(el.sgst) + Number(el.cgst) + Number(el.igst)) / 100).toFixed(2),
          totalAmount: Math.ceil(el.quantity * el.unitPrice*(1+(Number(el.sgst) + Number(el.cgst) + Number(el.igst))/100)),
        }
      });
      let supplierId = await addSupplier();
      let formData = new FormData();
      formData.append("goods", JSON.stringify(products));
      formData.append("billDoc", billDoc[0]);
      formData.append("supplierId", supplierId);
      formData.append("billNumber", billNumber);
      formData.append("billDate", billDate);
      let response = await api.put('purchase/expense', formData)
      if (response.status == 200) {
        setSnackbarObj({
          isShowSnackbar: true,
          message: 'Detail submitted Successfully',
          variant: 'success',
        })
        setProductData([]);
        setSupplier({});
      }
    }
  }

  const handleDeleteProduct = (index) => {
    let pdata = [...productData]
    pdata.splice(index, 1);
    setProductData(pdata);
  }

  const handleTotal = () => {
    let total = 0;
    productData.map(row => {
      total += row.quantity * row.unitPrice
    })
    return total ? total.toFixed(2) : total;
  }

  const handleChange = (e, rowIndex) => {
    let productListTemp = [...productData];
    productListTemp[rowIndex][e.target.name] = e.target.value
    setProductData(productListTemp)
  }

  const handleFormChange = (e) => {
    setSupplier(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  return (
    <div>
      <Card className='poCard' disablepadding="true">
        <CardContent>
          <div className='billingAddresses'>
            <div>
              <Form>
                <FormGroup>
                  <div className='row'>
                    <div className='col-md-3 mb-3'>
                      <Autocomplete
                        freeSolo
                        disablePortal
                        id="name"
                        name="name"
                        isOptionEqualToValue={(option, newValue) => {
                          return option._id === newValue._id;
                        }}
                        filterOptions={(x) => x}
                        getOptionLabel={(option) => `${option.name}`}
                        options={supplierList}
                        onChange={(e, option, reason) => setSupplierName(option, reason)}
                        onInputChange={(e, value, reason) => handleSupplierNameChange(e, reason)}
                        sx={{ width: "100%" }}
                        renderInput={(params) => (
                          <div ref={params.InputProps.ref}>
                            <Form.Control type="text" placeholder="Name " {...params.inputProps} />
                          </div>
                        )}
                      />
                    </div>
                    <div className='col-md-3 mb-3'>
                      <Form.Control type="text" placeholder="Business Name " name="businessName" value={supplier.businessName} onChange={handleFormChange} />
                    </div>
                    <div className='col-md-3 mb-3'>
                      <Form.Control type="text" placeholder="Contact No " name="contactNo" value={supplier.contactNo} onChange={handleFormChange} />
                    </div>
                    <div className='col-md-3 mb-3'>
                      <Form.Control type="email" placeholder="Email Id " name="emailId" value={supplier.emailId} onChange={handleFormChange} />
                    </div>
                    <div className='col-md-3 mb-3'>
                      <Form.Control type="text" placeholder="Address Line 1 " name="addressLine1" value={supplier.addressLine1} onChange={handleFormChange} />
                    </div>
                    <div className='col-md-3 mb-3'>
                      <Form.Control type="text" placeholder="Address Line 2 " name="addressLine2" value={supplier.addressLine2} onChange={handleFormChange} />
                    </div>
                    <div className='col-md-3 mb-3'>
                      <Form.Control type="number" placeholder="Pin Code" name="pincode" value={supplier.pincode} onChange={handleFormChange} />
                    </div>
                    <div className='col-md-3 mb-3'>
                      <Form.Control type="text" placeholder="City " name="city" value={supplier.city} onChange={handleFormChange} />
                    </div>
                    <div className='col-md-3 mb-3'>
                      <Form.Control type="text" placeholder="State " name="state" value={supplier.state} onChange={handleFormChange} />
                    </div>
                    <div className='col-md-3 mb-3'>
                      <Form.Control type="text" placeholder="Country " name="country" value={supplier.country} onChange={handleFormChange} />
                    </div>
                    <div className='col-md-3 mb-3'>
                      <Form.Control type="text" placeholder="PAN No " name="panNo" value={supplier.panNo} onChange={handleFormChange} />
                    </div>
                    <div className='col-md-3 mb-3'>
                      <Form.Control type="text" placeholder="GST Registration No " name="gstNo" value={supplier.gstNo} onChange={handleFormChange} />
                    </div>
                    <div className='col-md-3 mb-3'>
                      <Form.Control type="text" placeholder="Website  url " name="websiteUrl" value={supplier.websiteUrl} onChange={handleFormChange} />
                    </div>
                  </div>
                </FormGroup>
              </Form>
            </div>
            <div className='billDate'>
              <div >
                <label>Bill Date</label>
                <Form.Control type="date" name='billDate' onChange={(event) => setBillDate(event.target.value)} variant='standard' />
              </div>
              <div >
                <label>Bill Number</label>
                <Form.Control type="text" name='billNumber' value={billNumber} onChange={(event) => setBillNumber(event.target.value)} variant='standard' />
              </div>
              <div >
                <Button variant="contained" component="label">Upload File
                  <input type="file" name="billDoc" hidden onChange={(e) => { setBillDoc(e.target.files) }} />
                </Button>
                {billDoc && billDoc.length > 0 && <span>{billDoc[0]?.name}</span>}
              </div>
            </div>
          </div>
          <TableContainer className='shadow-none' component={Paper}>
            <Table sx={{ minWidth: 650, overflowX: 'auto' }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Sr No</TableCell>
                  <TableCell align="left">Description</TableCell>
                  <TableCell align="left">Unit Price</TableCell>
                  <TableCell align="left">Qty</TableCell>
                  <TableCell align="left">Net Amount</TableCell>
                  <TableCell align="left">HSN</TableCell>
                  <TableCell align="left">SGST</TableCell>
                  <TableCell align="left">CGST</TableCell>
                  <TableCell align="left">IGST</TableCell>
                  <TableCell align="left">Tax Amount</TableCell>
                  <TableCell align="left">Total Amount</TableCell>
                  <TableCell align="left">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {((productData || []).map((row, i) => (
                  <TableRow key={`${i}`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="left">{i + 1}</TableCell>
                    <TableCell align="left"><TextField variant="standard" value={row.description} name="description" onChange={(event) => handleChange(event, i)} /></TableCell>
                    <TableCell align="left"><TextField type='number' variant="standard" value={row.unitPrice} name="unitPrice" onChange={(event) => handleChange(event, i)} /></TableCell>
                    <TableCell align="left"><TextField type='number' variant="standard" value={row.quantity} name="quantity" onChange={(event) => handleChange(event, i)} /></TableCell>
                    <TableCell align="left">{(row.quantity * row.unitPrice).toFixed(2)}</TableCell>
                    <TableCell align="left"><TextField variant="standard" value={row.hsn} name="hsn" onChange={(event) => handleChange(event, i)} /></TableCell>
                    <TableCell align="left"><TextField type='number' variant="standard" value={row.sgst} name="sgst" onChange={(event) => handleChange(event, i)} /></TableCell>
                    <TableCell align="left"><TextField type='number' variant="standard" value={row.cgst} name="cgst" onChange={(event) => handleChange(event, i)} /></TableCell>
                    <TableCell align="left"><TextField type='number' variant="standard" value={row.igst} name="igst" onChange={(event) => handleChange(event, i)} /></TableCell>
                    <TableCell align="left">{(row.quantity * row.unitPrice * (Number(row.sgst) + Number(row.cgst) + Number(row.igst)) / 100).toFixed(2)}</TableCell>
                    <TableCell align="left">{Math.ceil(row.quantity * row.unitPrice*(1+((Number(row.sgst) + Number(row.cgst) + Number(row.igst))/100)))}</TableCell>
                    <TableCell align="center">
                      <div className='d-flex justify-content-center'>
                        <Button color='danger' onClick={() => handleDeleteProduct(i)}>
                          <DeleteIcon />
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                )))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className='addPurchase'>
            <div >
              <p className='footprice'>Total Items: {productData.length}</p>
            </div>
            <div >
              <p className='footprice'>Grand Total:  <CurrencyRupeeIcon />{handleTotal()}</p>
            </div>
            <div >
              <Button className='submitBtn' variant='contained' onClick={() => { setProductData((prevState) => { return [...prevState, { ...emptyProduct }] }) }}>Add More</Button>
            </div>
          </div>
        </CardContent>
        <CardActions className='cardActions justify-content-center'>
          <Button className='submitBtn' variant='contained' onClick={() => submitPurchaseData()}>Submit</Button>
        </CardActions>
      </Card>
      {snackbarObj.isShowSnackbar && (
        <Snackbar open={snackbarObj.isShowSnackbar} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() => setSnackbarObj({ isShowSnackbar: false, message: '', variant: '' })}>
          <Alert onClose={() => setSnackbarObj({ isShowSnackbar: false, message: '', variant: '' })} severity={snackbarObj.variant} sx={{ width: '100%' }}>
            {snackbarObj.message}
          </Alert>
        </Snackbar>)}
    </div>
  )
}
