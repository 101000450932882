import React from 'react'
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { login } from "../../service/Auth";
import Logo from '../../assets/image/logo.png';
import './customers.scss'

export default function CustomersForgotPassword() {
  const navigate = useNavigate();
  const handleLogin = ()=>{
    login();
    navigate("/");
  }
  return (
    <div className='login'>
      <div className='row justify-content-center align-items-center h-100'>
        <div className='col-md-4'>
        <Card className='loginCard'>
        <CardContent>
          <img className='loginLogo' src={Logo} alt='logo'/>
          <h3 className='loginHeading'>Recover your Account</h3>
          <div className='row '>
            <div className='col-md-12'>
              <TextField className='inputBox' id="email" label="Email" variant="standard" />
            </div>
          </div>
        </CardContent>
        <CardActions className='justify-content-center'>
          <Button className='loginBtn' variant='contained' onClick={handleLogin}>Submit</Button>
        </CardActions>
      </Card>
        </div>
      </div>
    </div>
  )
}
