/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Api from '../../service/Api'
import { useNavigate, useParams } from 'react-router-dom';
import { AddExpenseSchema } from '../schema/AddExpenseSchema';
import { useFormik } from 'formik';
import { billCategory } from '../common/constants';
const api = new Api()
const initialValues = {
    billDate: "",
    category: "",
    billNumber: "",
    amount: "",
    billDoc: "",
    employee: localStorage.getItem("defaultEmployeeId") ?? ""
};

export default function ExpenseDetails() {
    const navigate = useNavigate();
    const { expenseId } = useParams();
    const [employees, setEmployees] = useState([]);
    const [checked, setChecked] = useState(false);
    const [isEditPage, setIsEditPage] = useState(false);
    const { values, errors, touched, handleChange, handleSubmit, setValues, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: AddExpenseSchema,
        onSubmit: () => {
            console.log(values, expenseId)
            if (expenseId) {
                postFetchData();
            } else {
                handleSubmitBillUpload();
            }

        }

    })
    const handleSubmitBillUpload = async () => {
        try {
            let formData = new FormData();
            formData.append("amount", values.amount);
            formData.append("billDate", values.billDate);
            formData.append("billNumber", values.billNumber);
            formData.append("category", values.category);
            formData.append("billDoc", values.billDoc[0]);
            formData.append("employeeId", values.employee);
            localStorage.setItem("defaultEmployeeId", values.employee);
            let response = await api.put('expense', formData);
            if (response) {
                navigate('/expense')
            }
        }
        catch (err) {
            console.error(err);
        }

    }

    const getEmployee = async () => {
        let response = await api.get('employee');
        if (response) {
            setEmployees(response.data.data.map(_ => { return { value: _._id, label: _.name } }))
        }
    }


    async function editClientData() {
        try {
            let response = await api.get(`expense/${expenseId}`)
            if (response.status === 200) {
                const defaultValue = {
                    name: response.data.data.name,
                    email: response.data.data.email,
                    phoneNo: response.data.data.phoneNo,
                    line1: response.data.data.address.line1,
                    line2: response.data.data.address.line2,
                    city: response.data.data.address.city,
                    state: response.data.data.address.state,
                    pincode: response.data.data.address.pincode,
                    country: response.data.data.address.country,
                }
                setValues(defaultValue)//it replace the whole object
                setChecked(checked)
            }
        } catch (error) {
            console.error(error);
        }
    }

    async function postFetchData() {
        try {
            console.log(values)
            const body = {

            }
            // let response = await api.post(`expense/${expenseId}`, body)
            // if (response.status === 200) {
            //     console.log(response)
            //     navigate("/expense")

            // }
        }
        catch (err) {
            console.error(err);
        }
    }

    async function fileUpload() {

    }

    useEffect(() => {
        if (expenseId && expenseId !== "new") {
            editClientData()
        }
        getEmployee();
    }, [])

    return (
        <div className='addClient'>
            <div className='row justify-content-center'>
                <div className='col-md-12'>
                    <Card>
                        <CardContent>
                            <form onSubmit={handleSubmit}>
                                <div className='row '>
                                    <div className='col-md-4'>
                                        <FormControl variant="standard" sx={{ m: 0, minWidth: '100%' }}>
                                            <InputLabel>Employee</InputLabel>
                                            <Select
                                                className='inputBox'
                                                labelId="employee"
                                                id="employee"
                                                value={values.employee}
                                                label="Employee"
                                                name="employee"
                                                onChange={handleChange}
                                            >{(employees || []).map((employee, i) => {
                                                return (<MenuItem key={i} value={employee.value}>{employee.label}</MenuItem>)
                                            })}
                                            </Select>
                                        </FormControl>
                                        {<p className='form-error'>{errors.employee}</p>}
                                    </div>
                                    <div className='col-md-4'>
                                        <InputLabel>Bill Date</InputLabel>
                                        <TextField
                                            className='inputBox'
                                            type="date"
                                            id="bill"
                                            name='billDate'
                                            placeholder=""
                                            value={values.billDate}
                                            onChange={handleChange}
                                            variant='standard'
                                        />
                                        {<p className='form-error'>{errors.billDate}</p>}
                                    </div>
                                    <div className='col-md-4'>
                                        <FormControl variant="standard" sx={{ m: 0, minWidth: '100%' }}>
                                            <InputLabel>Category</InputLabel>
                                            <Select
                                                className='inputBox'
                                                labelId="category"
                                                id="category"
                                                value={values.category}
                                                label="Category"
                                                name="category"
                                                onChange={handleChange}
                                            >{(billCategory || []).map((category, i) => {
                                                return (<MenuItem key={i} value={category.value}>{category.label}</MenuItem>)
                                            })}
                                            </Select>
                                        </FormControl>
                                        {<p className='form-error'>{errors.category}</p>}
                                    </div>
                                    <div className='col-md-4'>
                                        <TextField className='inputBox' id="billNumber" name='billNumber' label="Bill Number" value={values.billNumber} onChange={handleChange} variant="standard" />
                                        {<p className='form-error'>{errors.billNumber}</p>}
                                    </div>
                                    <div className='col-md-4'>
                                        <TextField className='inputBox' name='amount' label="Amount" value={values.amount} onChange={handleChange} placeholder='Amount' variant="standard" />
                                        {<p className='form-error'>{errors.amount}</p>}
                                    </div>
                                    <div className='col-md-4'>
                                    </div>
                                    <div className='col-md-6'>
                                        <Button variant="contained" component="label">Upload File
                                            <input type="file" name="billDoc" hidden onChange={(e) => { setFieldValue("billDoc", e.target.files) }} />
                                        </Button>
                                        <span>{values.billDoc[0]?.name}</span>
                                    </div>
                                </div>
                                <CardActions className='justify-content-center'>
                                    <Button className='loginBtn' variant='contained' type="submit" >{expenseId ? "Update" : "Add"}</Button>
                                </CardActions>
                            </form>
                        </CardContent>

                    </Card>
                </div>
            </div>
        </div>
    )
}
