/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Api from '../../service/Api'
import { useNavigate, useParams } from 'react-router-dom';
import { AddEmployeeSchema } from '../schema/AddEmployeeSchema';
import { useFormik } from 'formik';
const api = new Api()
const initialValues = {
    name: "",
    email: "",
    phoneNo: "",
    city: "",
    country: "",
    line1: "",
    line2: "",
    pincode: "",
    state: "",
    password: "",
};
export default function EmployeeDetails() {
    const navigate = useNavigate();
    const { employeeId } = useParams();
    const [checked, setChecked] = useState(false);
    const [isEditPage, setIsEditPage] = useState(false);
    const { values, errors, touched, handleChange, handleSubmit, setValues } = useFormik({
        initialValues: initialValues,
        validationSchema: AddEmployeeSchema,
        onSubmit: () => {
            if (employeeId) {
                postFetchData();
            } else {
                handleSubmitClient();
            }

        }

    })
    const handleSubmitClient = async () => {
        try {
            let body = {
                name: values.name,
                email: values.email,
                password: values.password,
                phoneNo: values.phoneNo,
                address: {
                    line1: values.line1, 
                    line2: values.line2,
                    pincode: values.pincode,
                    city: values.city,
                    state: values.state,
                    country: values.country,
                }
            }

            let response = await api.put('employee', body)
            if (response) {
                navigate('/employee')
            }
        }
        catch (err) {
            console.error(err);
        }

    }

    async function editClientData() {
        try {
            let response = await api.get(`employee/${employeeId}`)
            if (response.status === 200) {
                const defaultValue = {
                    name: response.data.data.name,
                    email: response.data.data.email,
                    phoneNo: response.data.data.phoneNo,
                    line1: response.data.data.address.line1,
                    line2: response.data.data.address.line2,
                    city: response.data.data.address.city,
                    state: response.data.data.address.state,
                    pincode: response.data.data.address.pincode,
                    country: response.data.data.address.country,
                }
                setValues(defaultValue)//it replace the whole object
                setChecked(checked)
            }
        } catch (error) {
            console.error(error);
        }
    }

    async function postFetchData() {
        try {
            console.log("Add Client Post Values ", values)
            const body = {
                name: values.name,
                email: values.email,
                password: values.password,
                phoneNo: values.phoneNo,
                address: {
                    line1: values.line1,
                    line2: values.line2,
                    pincode: values.pincode,
                    city: values.city,
                    state: values.state,
                    country: values.country,
                }
            }
            let response = await api.post(`employee/${employeeId}`, body)
            if (response.status === 200) {
                console.log(response)
                navigate("/employee")

            }
        }
        catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (employeeId && employeeId !== "new") {
            editClientData()
        }
    
    }, [])

    return (
        <div className='addClient'>
            <div className='row justify-content-center'>
                <div className='col-md-12'>
                    <Card>
                        <CardContent>
                            <form onSubmit={handleSubmit}>
                                <div className='row '>
                                    <div className='col-md-4'>
                                        <TextField className='inputBox ms-1' id="name" name='name' label="Name" value={values.name} onChange={handleChange} variant="standard" />
                                        {<p className='form-error'>{errors.name}</p>}

                                    </div>
                                    <div className='col-md-4'>
                                        <TextField className='inputBox' id="email" name='email' label="Email" value={values.email} onChange={handleChange} variant="standard" />
                                        {<p className='form-error'>{errors.email}</p>}
                                    </div>
                                    <div className='col-md-4'>
                                    <TextField className='inputBox' id="password" name='password' label="Password" type="password" value={values.password} onChange={handleChange} variant="standard" />
                                    {<p className='form-error'>{errors.password}</p>}
                                </div>
                                    <div className='col-md-4'>
                                        <TextField className='inputBox' id="contactNo" name='phoneNo' label="Mobile No" value={values.phoneNo} onChange={handleChange} variant="standard" />
                                        {<p className='form-error'>{errors.phoneNo}</p>}
                                    </div>
                                    <h6 className='bold'>Address</h6>
                                    <div className='col-md-6'>
                                        <TextField multiline minRows={1} maxRows={2} className='inputBox' name='line1' value={values.line1} onChange={handleChange} placeholder='Address Line 1' variant="standard" />
                                        {<p className='form-error'>{errors.line1}</p>}
                                    </div>
                                    <div className='col-md-6'>
                                        <TextField multiline minRows={1} maxRows={2} className='inputBox' name='line2' value={values.line2} onChange={handleChange} placeholder='Address Line 2' variant="standard" />
                                    </div>
                                    <div className="col-md-3">
                                        <TextField className='inputBox' id="pincode" label="Pin Code" name='pincode' value={values.pincode} onChange={handleChange} variant="standard" />
                                        {<p className='form-error'>{errors.pincode}</p>}
                                    </div>
                                    <div className="col-md-3">
                                        <TextField className='inputBox' id="city" label="City" name='city' value={values.city} onChange={handleChange} variant="standard" />
                                        {<p className='form-error'>{errors.city}</p>}
                                    </div>
                                    <div className="col-md-3">
                                        <TextField className='inputBox' id="state" label="State" name='state' value={values.state} onChange={handleChange} variant="standard" />
                                        {<p className='form-error'>{errors.state}</p>}
                                    </div>
                                    <div className="col-md-3">
                                        <TextField className='inputBox' id="country" label="Country" name='country' value={values.country} onChange={handleChange} variant="standard" />
                                        {<p className='form-error'>{errors.country}</p>}
                                    </div>

                                </div>
                                <CardActions className='justify-content-center'>
                                    <Button className='loginBtn' variant='contained' type="submit" >{employeeId ? "Update" : "Add"}</Button>
                                </CardActions>
                            </form>
                        </CardContent>

                    </Card>
                </div>
            </div>
        </div>
    )
}
