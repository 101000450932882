import React, { Component } from 'react'
import Button from '@mui/material/Button';

export default class dashboard extends Component {
    render() {
        return (
            <div>
                <Button variant="text">Dashboard</Button>
               
            </div>
        )
    }
}
