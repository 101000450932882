import { React, useEffect, useState } from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import '../addProduct/addProduct.scss';
import '../../../style/variables.scss'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Tooltip from '@mui/material/Tooltip';
import ProductImge from '../../../assets/image/ProductImge.png';
import RemoveCircleOutlinedIcon from '@mui/icons-material/RemoveCircleOutlined';
import { useNavigate, useParams } from 'react-router-dom'
import Api from '../../../service/Api';
import { AddproductSchema } from '../../../components/schema/Addsupplierschema';
import { useFormik } from 'formik';
const api = new Api()
const initialValues = {
  name: "",
  unit: "",
  unitPrice: "",
  gst: "",
  sellPrice: "",
  purchasePrice: "",
  barcode: "",
  hsn: "",
  sku: "",
};

export default function AddStock() {

  const navigate = useNavigate();
  const { productId } = useParams();
  const [isEditPage, setIsEditPage] = useState(false);
  const { values, errors, handleChange, handleSubmit, setValues } = useFormik({
    initialValues: initialValues,
    validationSchema: AddproductSchema,
    onSubmit: () => {
      if (productId) {
        handleSubmitProduct();
      }

    }

  })


  const handleSubmitProduct = async () => {
   
    let body = {
      name: values.name,
      unit: values.unit,
      unitPrice: values.unitPrice,
      sellPrice: values.sellPrice,
      gst: values.gst,
      purchasePrice: values.purchasePrice,
      barcode: values.barcode,
      hsn: values.hsn,
      sku: values.sku,

    }
    console.log("body", body)
    if (isEditPage) {
      let response = await api.post(`product/${productId}`, body)
      if (response) {
        navigate('/product')
      }
    }
    else {
      let response = await api.put('product', body)
      if (response) {
        navigate('/product')
      }
    }
  }

  const editProductData = async () => {
    if (productId && productId !== 'new') {
      setIsEditPage(true);
      let response = await api.get('product', { productId: productId })
      if (response && response.data.data.length > 0) {
        const defaultValue = {
          name: response.data.data[0].name,
          unit: response.data.data[0].unit,
          unitPrice: response.data.data[0].unitPrice,
          gst: response.data.data[0].gst,
          sellPrice: response.data.data[0].sellPrice,
          purchasePrice: response.data.data[0].purchasePrice,
          barcode: response.data.data[0].barcode,
          hsn: response.data.data[0].hsn,
          sku: response.data.data[0].sku,
        }
        setValues(defaultValue)
      }
    }
  }

  useEffect(() => {
    if(productId){
      editProductData()
    }
   
  }, [])

  return (
    <div className=' addProduct'>
      <Card>
        <CardContent>
          {/* <h3 className='pageHeading'>Add Product</h3>
          <Divider className='customDivider' color="primary" /> */}

          {/* <h4 className='subHeading'>Product Details</h4> */}
          <form onSubmit={handleSubmit}>
            <div className='row'>
              <div className='col-md-4'>
                <TextField className='textBox' type="text" id="productName" label="Product Name" name="name" variant="standard" value={values.name} onChange={handleChange} />
                {<p className='form-error'>{errors.name}</p>}
              </div>
              <div className='col-md-4'>
                <TextField className='textBox' type="text" id="unit" label="Unit" variant="standard" name="unit" value={values.unit} onChange={handleChange} />
                {<p className='form-error'>{errors.unit}</p>}
              </div>
              <div className='col-md-4'>
                <TextField className='textBox' type="text" id="unitPrice" label="Unit Price	" name="unitPrice" variant="standard" value={values.unitPrice} onChange={handleChange} />
                {<p className='form-error'>{errors.unitPrice}</p>}
              </div>
              <div className='col-md-4'>
                <TextField className='textBox' type="text" id="SKU" label="SKU" name="sku" variant="standard" value={values.sku} onChange={handleChange} />
                {<p className='form-error'>{errors.sku}</p>}
              </div>
              <div className='col-md-4'>
                <TextField className='textBox' type="text" id="HSN" label="HSN" name="hsn" variant="standard" value={values.hsn} onChange={handleChange} />
                {<p className='form-error'>{errors.hsn}</p>}
              </div>
              <div className='col-md-4'>
                <TextField className='textBox' type="text" id="GST" label="GST" name="gst" variant="standard" value={values.gst} onChange={handleChange} />
                {<p className='form-error'>{errors.gst}</p>}
              </div>
              <div className='col-md-4'>
                <TextField className='textBox' type="text" id="SP" label="SP" name="sellPrice" variant="standard" value={values.sellPrice} onChange={handleChange} />
                {<p className='form-error'>{errors.sellPrice}</p>}
              </div>
              <div className='col-md-4'>
                <TextField className='textBox' type="text" id="PP" label="PP" name="purchasePrice" variant="standard" value={values.purchasePrice} onChange={handleChange} />
                {<p className='form-error'>{errors.purchasePrice}</p>}
              </div>
              <div className='col-md-4'>
                <TextField className='textBox' type="text" id="Barcode" label="Barcode" name="barcode" variant="standard" value={values.barcode} onChange={handleChange} />
                {<p className='form-error'>{errors.barcode}</p>}
              </div>
            </div>
            <div className='row align-items-center d-none'>
              <div className='col-md-2'>
                <label className="d-block">Product Imge</label>
                <Tooltip title="Upload Product Image">
                  <IconButton className='uploadImg' color="primary" size="large" aria-label="upload picture" component="label">
                    <input hidden size="large" accept="image/*" type="file" />
                    <PhotoCamera />
                  </IconButton>
                </Tooltip>
              </div>
              <div className='col-md-4'>
                <div className='uploadedImg'>
                  <image className='img-fluid' src={ProductImge}
                    alt="product Image" />
                  <Tooltip title="Remove Product Image">
                    <IconButton className='uploadImg' color="danger" size="large" aria-label="upload picture" component="label">
                      <input hidden size="large" accept="image/*" type="file" />
                      <RemoveCircleOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>

            </div>

            <CardActions className='cardFooter'>
              <Button className='footerBtn' size="large" variant="contained" type="submit">{isEditPage ? "Update" : "Add"}</Button>
            </CardActions>
          </form>
        </CardContent>
      </Card>
    </div>
  )
}