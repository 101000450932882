import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import "./expense.scss";
import "../../style/variables.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import Api from "../../service/Api";
import Filter from "../common/filter";
import SearchBar from "../common/search";
import moment from "moment";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Invoice from "../../assets/image/ProductImge.png";
import Bill from "../../assets/image/bill.png";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Swiper, SwiperSlide } from "swiper/react";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import PendingIcon from "@mui/icons-material/HourglassTopTwoTone";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import Images from "../../service/image";
import { Tooltip } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";

const api = new Api();
const image = new Images();

export default function Expense() {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [open, setOpen] = useState(false);
  const [auditData, setAuditData] = useState({});
  const [sorting, setSort] = React.useState("");

  const handleFilter = (event) => {
    setSort(event.target.value);
  };

  const handleClickOpen = (row) => {
    setOpen(true);
    setAuditData(row);
  };

  useEffect(() => {
    getExpense(sorting);
  }, [sorting]);

  const handleClose = () => {
    setAuditData({});
    setOpen(false);
  };
  const [expenseData, setExpenseData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getExpense();
  }, []);

  const getExpense = async () => {
    let response = await api.get("expense", { filter: sorting });
    if (response) {
      setExpenseData(response.data.data);
    }
  };

  const updateStatus = async (row, status) => {
    let response = await api.put(`expense/${row._id}`, { status: status });
    if (response) {
      getExpense();
      handleClose();
    }
  };

  const searchData = async (event) => {
    let response = await api.get("expense", {
      filter: sorting,
      searchName: event.target.value,
    });
    if (response) {
      setExpenseData(response.data.data);
    }
  };

  return (
    <div>
      <Card className="expenseCard" disablepadding="true">
        <CardContent>
          <div className="expenseBox">
            <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Sort By
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={sorting}
                onChange={handleFilter}
                label="Sort"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="newest">Newest Expense First</MenuItem>
                <MenuItem value="oldest">Oldest Expense First</MenuItem>
                <MenuItem value="highest">Amount High to Low</MenuItem>
                <MenuItem value="lowest">Amount Low to High</MenuItem>
              </Select>
            </FormControl>
            <div className="iconBox">
              <SearchBar searchData={searchData} />
            </div>
          </div>

          <TableContainer className="shadow-none" component={Paper}>
            <Table
              className="expenseData"
              sx={{ overflowX: "scroll" }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Sr. No</TableCell>
                  <TableCell>Employee Name</TableCell>
                  <TableCell align="left">Category</TableCell>
                  <TableCell align="left">Bill No</TableCell>
                  <TableCell align="left">Amount</TableCell>
                  <TableCell align="left">Bill Date</TableCell>
                  <TableCell align="left">Image</TableCell>
                  <TableCell align="left">Audit</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(expenseData || [])
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => (
                    <TableRow
                      key={row._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left">{i + 1}</TableCell>
                      <TableCell align="left">{row.employeeName}</TableCell>
                      <TableCell align="left">{row.category}</TableCell>
                      <TableCell align="left">
                        {row.billNumber || "NA"}
                      </TableCell>
                      <TableCell align="left">{row.amount}</TableCell>
                      <TableCell align="left">
                        {moment(row.billDate).format("L")}
                      </TableCell>
                      <TableCell align="left">
                        <img
                          src={image.getImageUrl(row?.billDocs[0]?.path, true)}
                          alt="Bill Img"
                          className="invoiceImg"
                        />
                      </TableCell>
                      <TableCell align="left">
                        <Tooltip title={row?.status}>
                          {row?.status === "rejected" ? (
                            <ClearIcon color="error" />
                          ) : row?.status === "accepted" ? (
                            <CheckIcon color="primary" />
                          ) : (
                            <PendingIcon color="warning" />
                          )}
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <div className="d-flex justify-content-center">
                          <Button
                            color="secondary"
                            onClick={() => handleClickOpen(row)}
                          >
                            <VisibilityIcon />
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={expenseData.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </CardContent>
      </Card>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="modalHeader">
          <h3 className="modalHeading">
            Emp Name: <span>{auditData?.employeeName}</span>
          </h3>
          <div className="">|</div>
          <h3 className="modalHeading">
            Invoice <span>{auditData?.billNumber}</span>
          </h3>
          <div className="">|</div>
          <h3 className="modalHeading">
            Bill Amount <span>{auditData?.amount}</span>
          </h3>
        </div>
        <DialogContent>
          <Swiper
            style={{
              "--swiper-navigation-color": "#000",
              "--swiper-pagination-color": "#000",
            }}
            loop={true}
            spaceBetween={10}
            navigation={true}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper2"
          >
            {auditData?.billDocs?.map((doc, index) => (
              <SwiperSlide key={index}>
                <img
                  src={image.getImageUrl(doc.path, true)}
                  alt={`Bill Img ${index + 1}`}
                  className="modalMainBill"
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <Swiper
            onSwiper={setThumbsSwiper}
            loop={true}
            spaceBetween={10}
            slidesPerView={4}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper"
          >
            {auditData?.billDocs?.map((doc, index) => (
              <SwiperSlide key={index}>
                <img
                  src={image.getImageUrl(doc.path, true)}
                  alt={`Bill Thumb ${index + 1}`}
                  className="modalBill"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </DialogContent>

        <DialogActions className="justify-content-center">
          {auditData?.status == "pending" && (
            <>
              <Button onClick={() => updateStatus(auditData, "rejected")}>
                Reject
              </Button>
              <Button
                onClick={() => updateStatus(auditData, "accepted")}
                variant="contained"
              >
                Approve
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
