import { Card, CardContent, Divider } from '@mui/material'
import React from 'react'
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
// import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
// const Item = styled(Paper)(({ theme }) => ({
//     backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//     ...theme.typography.body2,
//     padding: theme.spacing(1),
//     textAlign: 'center',
//     color: theme.palette.text.secondary,
// }));

export default function AddTask() {
    const [user, setUser] = React.useState('');
    const handleChange = (event) => {
        setUser(event.target.value);
    };
  return (
    <div className='container'>
        <Card>
        <CardContent>
            <div className='d-flex w-100 mt-0 justify-content-between pe-3 align-items-center'>
            <h2 className='dialogTitle mb-0 mt-2'>Add Task For Employee</h2>     
                          <div className='d-flex align-items-end'>
                              <h2 className='selectHeading'>Assign</h2>
                              <FormControl variant="standard" sx={{ m: 0, minWidth: 150 }}>
                                  <InputLabel id="demo-simple-select-label">Select Employee</InputLabel>
                                  <Select
                                      className='text-left'
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={user}
                                      label="Select Employee"
                                      onChange={handleChange}

                                  >
                                      <MenuItem value={10}>All</MenuItem>
                                      <MenuItem value={20}>Nishant</MenuItem>
                                      <MenuItem value={30}>Aman</MenuItem>
                                      <MenuItem value={40}>Asha</MenuItem>
                                  </Select>
                              </FormControl>
                          </div>
                      </div>
                      <TableContainer className='mt-3' component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>#</TableCell>
                                            <TableCell align="left">Task Name</TableCell>
                                            <TableCell align="left">Task Type</TableCell>
                                            <TableCell align="left">Credits</TableCell>
                                            <TableCell align="left">Start Date</TableCell>
                                            <TableCell align="left">End Date</TableCell>
                                            <TableCell align="right">Add More</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                1
                                            </TableCell>
                                            <TableCell align="left">
                                                <TextField id="standard-basic" label="Enter New Task" sx={{ width: 150 }} variant="standard" />
                                            </TableCell>
                                            <TableCell align="left">
                                                <FormControl sx={{ width: 170 }}>
                                                    {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                    >
                                                        <FormControlLabel sx={{ fontSize: 12 }} value="One" control={<Radio />} label="One" />
                                                        <FormControlLabel value="Routine" control={<Radio />} label="Routine" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align='left'>
                                                <TextField id="standard-basic" placeholder='Points' sx={{ width: 50 }} variant="standard" />
                                            </TableCell>
                                            <TableCell align='left'>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer sx={{ width: 85 }} components={['DatePicker', 'DatePicker']}>
                                                        <DatePicker
                                                            placeholder="From"
                                                            slotProps={{ textField: { variant: 'standard', } }}
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </TableCell>
                                            <TableCell align='left'>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer sx={{ width: 85 }} components={['DatePicker', 'DatePicker']}>
                                                        <DatePicker
                                                            placeholder="To"
                                                            slotProps={{ textField: { variant: 'standard', } }}
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </TableCell>
                                            <TableCell align="right">
                                                <IconButton aria-label="delete">
                                                    <AddIcon className='iconTable' />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>
            </CardContent>
            <Divider/>
            <CardContent>
          
            </CardContent>
        </Card>
    </div>
  )
}
