import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import '../createInvoice/createInvoice.scss'
import Button from '@mui/material/Button';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import TextField from '@mui/material/TextField';
import TableFooter from '@mui/material/TableFooter';
import CardActions from '@mui/material/CardActions';
import InfoInput from '../common/infoInput';
import Api from '../../service/Api'
const api = new Api()

export default function AddGrn() {
    const [po, setPoList] = useState([])
    const [selectedPo, setSelectedPo] = useState(null);
    const [product, setProduct] = useState([])
    const navigate = useNavigate();

    const getPurchaseOrders = async () => {
        let response = await api.get('purchaseorder');
        if (response) {
            setPoList(response.data.data)
        }
    }
    const handlePONameChange = async (event, value, reason) => {
        if (reason === 'input') {
            if (event.target.value === "") {
                setPoList([])
                setSelectedPo(null);
            }
            else {
                let response = await api.get("purchaseorder", { searchName: event.target.value });
                console.log("Response", response.data.data)
                if (response.status == 200) {
                    setPoList(response.data.data);
                }
            }
        }
    }
    const setPoListName = (e, option, reason) => {
        if (reason === 'selectOption') {
            setSelectedPo(option);
            setProduct(option.products)
        }
    }

    const handleGrandAmount = () => {
        let total = 0;
        product.map(row => {
            total += row.receivedQuantity * row.purchasePrice
        })
        return total.toFixed(2);
    }
    const submitGRNData = async () => {
        let products = product.map(el => {
            return {
                productId: el._id,
                quantity: el.receivedQuantity,
            }
        });

        let body = {
            products: products,
            purchaseId: selectedPo._id,
        }

        let response = await api.put('grn', body)
        if (response.status == 200) {
            navigate('/grn');
        }
    }
    const handleQuantity = (e, rowIndex) => {
        let productTemp = [...product];
        productTemp[rowIndex]["receivedQuantity"] = parseInt(e.target.value) < productTemp[rowIndex]["quantity"] ? parseInt(e.target.value) : productTemp[rowIndex]["quantity"];
        setProduct(productTemp);
    }

    const handelGrandQuantity = () => {
        let total = 0;
        product.map(row => {
            total += row.receivedQuantity;
        })
        return total;
    }

    useEffect(() => {
        getPurchaseOrders()
    }, [])

    return (
        <div>
            <Card className='poCard' disablepadding="true">
                <CardContent>
                    <div className='poBox'>
                        <h3 className='heading'></h3>
                    </div>
                    <div className='billingAddresses'>
                        <InfoInput grnBool={true}
                            nameList={po}
                            selectedName={selectedPo}
                            onSelectOption={setPoListName}
                            onInputChange={handlePONameChange}
                        />
                    </div>
                    <TableContainer className='shadow-none' component={Paper}>
                        <div className='text-end' style={{ width: 300, marginRight: 'auto', marginBottom: 8 }}>
                            <TextField sx={{
                                '& > :not(style)': { width: '30ch' },
                            }}
                                variant="filled"
                                placeholder='search (Barcode)'
                                type='search'
                            />
                        </div>
                        <Table sx={{ minWidth: 650, overflowX: 'auto' }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sr. NO</TableCell>
                                    <TableCell align="left">Description</TableCell>
                                    <TableCell align="left">Barcode</TableCell>
                                    <TableCell align="left">Unit Price</TableCell>
                                    <TableCell align="left">HSN</TableCell>
                                    <TableCell align="left">SGST</TableCell>
                                    <TableCell align="left">CGST</TableCell>
                                    <TableCell align="left">Tax Amount</TableCell>
                                    <TableCell align="left">Total Amount</TableCell>
                                    <TableCell align="left">Qty</TableCell>
                                    <TableCell align="left">Receive QTY</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {product.map((row, index) => (
                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        key={row._id}
                                    >
                                        <TableCell align="left">
                                            {index + 1}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="left">{row.barcode}</TableCell>
                                        <TableCell align="left">{row.purchasePrice}</TableCell>
                                        <TableCell align="left">{row.hsn}</TableCell>
                                        <TableCell align="left">{row.gst / 2}</TableCell>
                                        <TableCell align="left">{row.gst / 2}</TableCell>
                                        <TableCell align="left">{row.receivedQuantity && row.receivedQuantity !== 0 ? (row.purchasePrice * row.receivedQuantity * (row.gst / 100)).toFixed(2) : 0}</TableCell>
                                        <TableCell align="left">{row.receivedQuantity && row.receivedQuantity !== 0 ? (row.purchasePrice * row.receivedQuantity).toFixed(2) : 0}</TableCell>
                                        <TableCell align="left">{row.quantity}</TableCell>
                                        <TableCell align="left"><TextField variant="standard" defaultValue={row.receivedQuantity ? row.receivedQuantity : 0} onChange={(event) => handleQuantity(event, index)} /></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell colSpan={11} align="right">
                                        <p className='footprice'>Total Items: {handelGrandQuantity()}</p>
                                    </TableCell>
                                    <TableCell colSpan={6} align="left">
                                        <p className='footprice'>Grand Total:  <CurrencyRupeeIcon />{handleGrandAmount()}</p>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </CardContent>
                <CardActions className='cardActions justify-content-center'>
                    <Button className='submitBtn' variant='contained' onClick={submitGRNData}>Submit</Button>
                </CardActions>
            </Card>
        </div>
    )
}
