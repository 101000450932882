import { Card, CardContent, Fab, FormLabel, Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import React from 'react'
import './task.scss'
import SearchBar from '../common/search';
import AddIcon from '@mui/icons-material/Add';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Slider from '@mui/material/Slider';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Add } from '@mui/icons-material';
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
export default function Task() {
    const [alignment, setAlignment] = React.useState('left');

    const handleToggelChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    const children = [
        <ToggleButton sx={{ width: 30, height: 30 }} value="left" key="mno">
            M
        </ToggleButton>,
        <ToggleButton sx={{ width: 30, height: 30 }} value="center" key="tue">
            T
        </ToggleButton>,
        <ToggleButton sx={{ width: 30, height: 30 }} value="right" key="wed">
            W
        </ToggleButton>,
        <ToggleButton sx={{ width: 30, height: 30 }} value="thu" key="thu">
            T
        </ToggleButton>,
        <ToggleButton sx={{ width: 30, height: 30 }} value="fri" key="fri">
            F
        </ToggleButton>,
        <ToggleButton sx={{ width: 30, height: 30 }} value="sat" key="sat">
            S
        </ToggleButton>,
        <ToggleButton sx={{ width: 30, height: 30 }} value="sun" key="sun">
            S
        </ToggleButton>,
    ];
    const control = {
        value: alignment,
        onChange: handleToggelChange,
        exclusive: true,
    };
    // const [value, setValue] = React.useState(dayjs('2022-04-17'));
    const [user, setUser] = React.useState('');

    const handleChange = (event) => {
        setUser(event.target.value);
    };
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div className='mt-3 container'>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={1}>
                    <Grid item lg={12 / 7} md={4} sm={6} xs={6}>
                        <Item>
                            <h2 className='tastBoxHeading'>All Tasks</h2>
                            <p className='tastBoxText'>5</p>
                        </Item>
                    </Grid>
                    <Grid item lg={12 / 7} md={4} sm={6} xs={6}>
                        <Item>
                            <h2 className='tastBoxHeading'>Pending</h2>
                            <p className='tastBoxText'>5</p>
                        </Item>
                    </Grid>
                    <Grid item lg={12 / 7} md={4} sm={6} xs={6}>
                        <Item>
                            <h2 className='tastBoxHeading'>Inprogress</h2>
                            <p className='tastBoxText'>5</p>
                        </Item>
                    </Grid>
                    <Grid item lg={12 / 7} md={4} sm={6} xs={6}>
                        <Item>
                            <h2 className='tastBoxHeading'>Completed</h2>
                            <p className='tastBoxText'>5</p>
                        </Item>
                    </Grid>
                    <Grid item lg={12 / 7} md={4} sm={6} xs={6}>
                        <Item>
                            <h2 className='tastBoxHeading'>Approved</h2>
                            <p className='tastBoxText'>5</p>
                        </Item>
                    </Grid>
                    <Grid item lg={12 / 7} md={4} sm={6} xs={6}>
                        <Item>
                            <h2 className='tastBoxHeading'>Monthly Point</h2>
                            <p className='tastBoxText'>5</p>
                        </Item>
                    </Grid>
                    <Grid item lg={12 / 7} md={4} sm={6} xs={6}>
                        <Item>
                            <h2 className='tastBoxHeading'>Total Point</h2>
                            <p className='tastBoxText'>5</p>
                        </Item>
                    </Grid>
                </Grid>
            </Box>
            <Card className='mt-3'>
                <CardContent>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h2 className='heading'>My Tasks</h2>
                        <div className='d-flex align-items-center'>
                            <SearchBar />
                            {/* <Link> */}
                            <Fab onClick={handleClickOpen} color="primary" aria-label="add" size="medium">
                                <AddIcon />
                            </Fab>
                            {/* </Link> */}
                        </div>
                    </div>
                    <TableContainer className='mt-3' component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell align="left">Project Name</TableCell>
                                    <TableCell align="left">Given By</TableCell>
                                    <TableCell align="left">Task</TableCell>
                                    <TableCell align="left">Type</TableCell>
                                    <TableCell align="left">Credits</TableCell>
                                    <TableCell align="left">Approve Credits</TableCell>
                                    <TableCell align="left">Duration</TableCell>
                                    <TableCell align="left">Status</TableCell>
                                    <TableCell align="right">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        1
                                    </TableCell>
                                    <TableCell align="left"><p className='tableText'>Lorem Ipsum</p> </TableCell>
                                    <TableCell align="left"><p className='tableText'>Kiran Pal Singh</p></TableCell>
                                    <TableCell align="left"><p className='noWrapTableText'>Lorem Ipsum is simprinting</p> </TableCell>
                                    <TableCell align="left"><p className='tableText'>Once</p></TableCell>
                                    <TableCell align="left"><p className='tableText'>8</p></TableCell>
                                    <TableCell align="left"><p className='tableText'>8</p></TableCell>
                                    <TableCell align="left">
                                        <p className='taskStart'>Start- 05-02-2020</p>
                                        <p className='taskEnd'>Start- 06-02-2020</p>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Box sx={{ width: 118 }}>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <Typography id="input-slider" fontSize={12} gutterBottom>
                                                    100%
                                                </Typography>
                                                <Typography id="input-slider" fontSize={12} gutterBottom>
                                                    Complete
                                                </Typography>
                                            </div>
                                            <Slider
                                                color="success"
                                                size="small"
                                            // getAriaValueText={valuetext}
                                            // step={1}
                                            // marks={marks}
                                            // valueLabelDisplay="on"
                                            />
                                        </Box>

                                    </TableCell>
                                    <TableCell align="right">
                                        <div className="d-flex">
                                            <IconButton aria-label="delete">
                                                <CreateIcon className='iconTable' />
                                            </IconButton>
                                            <IconButton aria-label="delete">
                                                <DeleteIcon className='iconTableDelete' />
                                            </IconButton>

                                        </div>

                                    </TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            component: 'form',
                            onSubmit: (event) => {
                                event.preventDefault();
                                const formData = new FormData(event.currentTarget);
                                const formJson = Object.fromEntries(formData.entries());
                                const email = formJson.email;
                                console.log(email);
                                handleClose();
                            },
                        }}
                    >
                        <div className='d-flex mt-3 justify-content-between pe-3 align-items-start'>
                            <DialogTitle className='dialogTitle'>Add Ticket</DialogTitle>
                            <div className='d-flex align-items-end'>
                                {/* <h2 className='selectHeading'>Assign</h2> */}

                            </div>
                        </div>
                        <DialogContent className='' sx={{ width: 600 }}>
                            <div className='row'>
                                <div className='col-md-6 mb-3'>
                                    <TextField id="standard-basic" label="Enter New Task" fullWidth variant="standard" />
                                </div>
                                <div className='col-md-6 mb-3'>
                                    <FormControl variant="standard" fullWidth sx={{ m: 0 }}>
                                        <InputLabel id="demo-simple-select-label">Select Employee</InputLabel>
                                        <Select
                                            className='text-left'
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={user}
                                            label="Select Employee"
                                            onChange={handleChange}
                                            sx={{ m: 0 }}
                                        >
                                            <MenuItem value={10}>Nishant</MenuItem>
                                            <MenuItem value={20}>Aman</MenuItem>
                                            <MenuItem value={30}>Asha</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className='col-md-6 mb-3'>
                                    <FormControl variant="standard" fullWidth sx={{ m: 0 }}>
                                        <InputLabel id="demo-simple-select-label">Priority</InputLabel>
                                        <Select
                                            className='text-left'
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={user}
                                            label="Select Employee"
                                            onChange={handleChange}

                                        >
                                            <MenuItem value={10}>High</MenuItem>
                                            <MenuItem value={20}>Normal</MenuItem>
                                            <MenuItem value={30}>Low</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className='col-md-6 mb-3'>
                                    <FormControl>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Task Type</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                        >
                                            <FormControlLabel sx={{ fontSize: 12 }} value="One" control={<Radio />} label="One" />
                                            <FormControlLabel value="Routine" control={<Radio />} label="Routine" />
                                        </RadioGroup>
                                    </FormControl>
                                    <Stack spacing={2} alignItems="start">
                                        <ToggleButtonGroup size="small" aria-label="Small sizes" size="small" {...control} aria-label="Small sizes">
                                            {children}
                                        </ToggleButtonGroup>

                                    </Stack>
                                </div>
                            </div>

                            <TableContainer className='mt-3' component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>#</TableCell>
                                            <TableCell align="left">Task Name</TableCell>
                                            <TableCell align="left">Task Type</TableCell>
                                            <TableCell align="left">Credits</TableCell>
                                            <TableCell align="left">Start Date</TableCell>
                                            <TableCell align="left">End Date</TableCell>
                                            <TableCell align="right">Add More</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                1
                                            </TableCell>
                                            <TableCell align="left">
                                                <TextField id="standard-basic" label="Enter New Task" sx={{ width: 150 }} variant="standard" />
                                            </TableCell>
                                            <TableCell align="left">
                                                <FormControl sx={{ width: 170 }}>
                                                    {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                    >
                                                        <FormControlLabel sx={{ fontSize: 12 }} value="One" control={<Radio />} label="One" />
                                                        <FormControlLabel value="Routine" control={<Radio />} label="Routine" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align='left'>
                                                <TextField id="standard-basic" placeholder='Points' sx={{ width: 50 }} variant="standard" />
                                            </TableCell>
                                            <TableCell align='left'>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer sx={{ width: 85 }} components={['DatePicker', 'DatePicker']}>
                                                        <DatePicker
                                                            placeholder="From"
                                                            slotProps={{ textField: { variant: 'standard', } }}
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </TableCell>
                                            <TableCell align='left'>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer sx={{ width: 85 }} components={['DatePicker', 'DatePicker']}>
                                                        <DatePicker
                                                            placeholder="To"
                                                            slotProps={{ textField: { variant: 'standard', } }}
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </TableCell>
                                            <TableCell align="right">
                                                <IconButton aria-label="delete">
                                                    <AddIcon className='iconTable' />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </DialogContent>
                        <DialogActions className='justify-content-center'>
                            <Button type="submit" className='saveBtn'>Save</Button>
                        </DialogActions>
                    </Dialog>
                </CardContent>

            </Card>
        </div>
    )
}
