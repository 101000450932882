import './App.scss';
// import Header from './template/header';
import Sales from './components/sales/sales';
import CreateInvoice from './components/createInvoice/createInvoice';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from "react";

import {
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import Header from './template/header';
import Dashboard from './components/dashboard/dashboard';
import PurchaseList from "./components/purchase/purchaseList";
import Product from "./components/product/product";
import Inventory from "./components/inventory/inventory";
import AddProduct from "./components/product/addProduct/addProduct";
import CustomersLogin from "./components/customers/customersLogin";
import CustomersSignup from "./components/customers/customersSignup";
import CustomersForgotPassword from "./components/customers/customersForgotPassword";
import AddSupplier from "./components/supplier/addSupplier";
import Supplier from "./components/supplier/supplier";
import Client from "./components/client/client";
import AddClient from "./components/client/addClient";
import Setting from "./components/setting/profile";
import EmployeeDetails from "./components/employee/employeeDetails";
import Employee from "./components/employee/employee";
import CreatePurchase from './components/purchase/createPurchase'
import CreateDirectPurchaseOrder from './components/purchase/CreateDirectPurchaseOrder'
import ExpenseDetails from './components/expense/expenseDetails';
import Expense from './components/expense/expense';
import CreatePurchaseExpense from './components/expense/purchase';
import PurchaseExpenses from './components/expense/purchaseExpenses';

import { PrivateRoute, PublicRoute } from './helper';
import AddGrn from './components/grn/addGrn';
import Grn from './components/grn/grn';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import Task from './components/task/task';
import AddTask from './components/task/addTask';


const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#414143',
    },
    white: {
      main: '#ffffff'
    },
    black: {
      main: '#212121'
    },
    danger: {
      main: '#CA0C00'
    },
  },
});


function App() {
  const locations = useLocation();
  const navigate = useNavigate();

  axios.interceptors.request.use(request => {
    // console.log(localStorage.getItem('token'))
    const token = localStorage.getItem('token');
    if (token) {
      request.headers['Authorization'] = 'Bearer ' + token;
    }
    return request;
  })

  axios.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    const status = error.response?.status || 500;
    if (status === 403) {
      navigate("/login")
      //  window.location = window.location.protocol + "//" + window.location.host + "/login"
    } else {
      return Promise.reject(error); // Delegate error to calling side
    }
  });


  return (
    <ThemeProvider theme={theme}>
      {locations.pathname !== "/login" && locations.pathname !== "/" && locations.pathname !== "/signup" && locations.pathname !== '/forgot-password' && <Header />}
      <div className='App'>
        <Routes>
          <Route path="/login" element={
            <PublicRoute>
              <CustomersLogin />
            </PublicRoute>} />
          {/* <Route path="/" element={<Navigate to="/login" />} /> */}

          <Route path="/signup" element={
            <PublicRoute>
              <CustomersSignup />
            </PublicRoute>} />
          <Route path="/forgot-password" element={
            <PublicRoute>
              <CustomersForgotPassword />
            </PublicRoute>} />

          <Route path="/sales" element={
            <PrivateRoute>
              <Sales />
            </PrivateRoute>}
          />
          <Route path="/create-invoice" element={
            <PrivateRoute>
              <CreateInvoice />
            </PrivateRoute>}
          />
          <Route path="/purchase-list" element={
            <PrivateRoute>
              <PurchaseList />
            </PrivateRoute>
          } />
          <Route path="/product" element={
            <PrivateRoute>
              <Product />
            </PrivateRoute>
          } />
          <Route path="/inventory" element={
            <PrivateRoute>
              <Inventory />
            </PrivateRoute>
          } />
          <Route path="/product/:productId" element={
            <PrivateRoute>
              <AddProduct />
            </PrivateRoute>} />
          <Route path="/supplier" element={
            <PrivateRoute>
              <Supplier />
            </PrivateRoute>} />
          <Route path="/supplier" element={<PrivateRoute>
            <Supplier />
          </PrivateRoute>} />
          <Route path="/add-supplier" element={<PrivateRoute>
            <AddSupplier />
          </PrivateRoute>} />
          <Route path="/add-supplier/:id" element={<PrivateRoute>
            <AddSupplier />
          </PrivateRoute>} />
          <Route path="/customers" element={<PrivateRoute>
            <Client />
          </PrivateRoute>} />
          <Route path="/employee" element={<PrivateRoute>
            <Employee />
          </PrivateRoute>} />
          <Route path="/task" element={<PrivateRoute>
            <Task />
          </PrivateRoute>} />
          <Route path="/add-task" element={<PrivateRoute>
            <AddTask />
          </PrivateRoute>} />
          <Route path="/employee/new" element={<PrivateRoute>
            <EmployeeDetails />
          </PrivateRoute>} />
          <Route path="/employee/:employeeId" element={<PrivateRoute>
            <EmployeeDetails />
          </PrivateRoute>} />
          <Route path="/expense" element={<PrivateRoute>
            <Expense />
          </PrivateRoute>} />
          <Route path="/purchase/expense/new" element={<PrivateRoute>
            <CreatePurchaseExpense />
          </PrivateRoute>} />
          <Route path="/purchase/expense" element={<PrivateRoute>
            <PurchaseExpenses />
          </PrivateRoute>} />
          <Route path="/expense/new" element={<PrivateRoute>
            <ExpenseDetails />
          </PrivateRoute>} />
          <Route path="/expense/:employeeId" element={<PrivateRoute>
            <ExpenseDetails />
          </PrivateRoute>} />
          <Route path="/client/:clientId" element={
            <PrivateRoute>
              <AddClient />
            </PrivateRoute>} />
          <Route path="/client/new" element={
            <PrivateRoute>
              <AddClient />
            </PrivateRoute>} />
          <Route path="/setting" element={
            <PrivateRoute>
              <Setting />
            </PrivateRoute>} />
          <Route path="/create-purchase" element={
            <PrivateRoute>
              <CreatePurchase />
            </PrivateRoute>
          } />
          <Route path="/create-direct-purchase" element={
            <PrivateRoute>
              <CreateDirectPurchaseOrder />
            </PrivateRoute>
          } />
          <Route path="/grn" element={
            <PrivateRoute>
              <Grn />
            </PrivateRoute>
          } />
          <Route path="/create-grn" element={
            <PrivateRoute>
              <AddGrn />
            </PrivateRoute>
          } />
          <Route path="/supplier" element={
            <PrivateRoute>
              <Supplier />
            </PrivateRoute>} />
          <Route path="/dashboard" element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>} />
          <Route path="/" element={
            <PrivateRoute >
              <CustomersLogin />
            </PrivateRoute>} />
          {/* <Route path="/" element={<Navigate to="/login" />} /> */}

        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
