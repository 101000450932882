import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Link } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import './client.scss';
import '../../style/variables.scss'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { styled, alpha } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import Api from '../../service/Api'
import Filter from '../common/filter';
import SearchBar from '../common/search';
const api = new Api()

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '0',
            '&:focus': {
                width: '20ch',
                border: '1px solid #e0e0e0',
                borderRadius: '4px',
                boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
            },
        },
    },
}));

export default function Client() {

    const [clientData, setClientData] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        getClient()
    }, [])

    const getClient = async () => {
        let response = await api.get('client');
        if (response) {
            setClientData(response.data.data)
        }
    }

    const deleteClient = async (row) => {
        let response = await api.delete(`client/${row._id}`);
        if (response) {
            getClient();
        }
    }

    const searchData = async (event) => {
        let response = await api.get('client', { searchName: event.target.value });
        if (response) {
            setClientData(response.data.data);
        }
    }

    return (
        <div>
            <Card className='clientCard' disablepadding="true">
                <CardContent>
                    <div className='clientBox'>
                        <Filter />
                        <div className='iconBox'>
                            <SearchBar searchData={searchData} />

                            <Link to="/client/new">
                                <Fab color="primary" aria-label="add" size="medium">
                                    <AddIcon />
                                </Fab>
                            </Link>
                        </div>
                    </div>

                    <TableContainer className='shadow-none' component={Paper}>
                        <Table className='clientData' sx={{ overflowX: 'scroll' }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sr. No</TableCell>
                                    <TableCell> Name</TableCell>
                                    <TableCell align="left">Mobile No.</TableCell>
                                    <TableCell align="left">Email</TableCell>
                                    <TableCell align="center">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {((clientData || []).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                    <TableRow key={row._id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="left">
                                            {i + 1}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.name.toUpperCase()}
                                        </TableCell>
                                        <TableCell align="left">{row.phoneNo}</TableCell>
                                        <TableCell align="left">{row.email}</TableCell>

                                        <TableCell align="center">
                                            <div className='d-flex justify-content-center'>
                                                <Button color='danger' onClick={() => deleteClient(row)}>
                                                    <DeleteIcon />
                                                </Button>
                                                <Button color='secondary' component={Link} to={'/client/' + row._id} >
                                                    <EditIcon />
                                                </Button>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )))}
                            </TableBody>
                            {/* <TableFooter className='tablePagination'>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        colSpan="13"
                                        count="2"
                                        rowsPerPage="3"
                                        page="10"

                                        onPageChange="2"
                                    // onRowsPerPageChange="4"
                                    // ActionsComponent="5"
                                    />
                                </TableRow>
                            </TableFooter> */}
                        </Table>
                    </TableContainer>
                    <TablePagination
                        component="div"
                        count={clientData.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[5,10,25,50,100]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </CardContent>

            </Card>
        </div>
    )
}

