import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import '../createInvoice/createInvoice.scss'
import Button from '@mui/material/Button';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import TextField from '@mui/material/TextField';
import TableFooter from '@mui/material/TableFooter';
import CardActions from '@mui/material/CardActions';
import DeleteIcon from '@mui/icons-material/Delete';
import { Autocomplete, Alert, Snackbar } from '@mui/material';
import InfoInput from '../common/infoInput';
import Api from '../../service/Api';

const api = new Api();
export default function CreateDirectPurchaseOrder() {
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [supplierList, setSupplierList] = useState([]);
  const [productData, setProductData] = useState([]);
  const [productList, setProductList] = useState([]);
  const [clear, setClear] = useState(null);
  const [snackbarObj, setSnackbarObj] = useState({ isShowSnackbar: false, message: '', variant: '' });


  const handleSupplierNameChange = async (event, value, reason) => {
    if (reason === 'input') {
      if (event.target.value === "") {
        setSupplierList([])
        setSelectedSupplier(null);
      }
      else {
        let response = await api.get("supplier", { searchName: event.target.value });
        if (response.status == 200) {
          setSupplierList(response.data.data);
        }
      }
    }
  }

  const setSupplierName = (e, option, reason) => {
    if (reason === 'selectOption') {
      setSelectedSupplier(option);
    }
  }

  const handleValidation = () => {
    if (!(productData && productData.length > 0)) {
      setSnackbarObj({
        isShowSnackbar: true,
        message: 'Product are empty',
        variant: 'warning'
      })
      return false;
    }
    if (!(selectedSupplier?._id)) {
      setSnackbarObj({
        isShowSnackbar: true,
        message: 'Supplier not selected',
        variant: 'warning'
      })
      return false;
    }
    let emptyQuan = false;
    for (var i = 0; i < productData.length; i++) {
      if (!productData[i].quantity) {
        emptyQuan = true;
        break
      }
    }
    if (emptyQuan) {
      setSnackbarObj({
        isShowSnackbar: true,
        message: 'Please fill all product detail',
        variant: 'warning'
      })
      return false;
    }
    return true
  }

  const submitPurchaseData = async () => {
    if (handleValidation()) {
      let products = productData.map(el => {
        return {
          productId: el._id,
          quantity: el.quantity,
          purchasePrice: el.unitPrice
        }
      });

      let body = {
        products: products,
        supplierId: selectedSupplier._id,
      }

      let response = await api.put('directpurchase', body)
      if (response.status == 200) {
        setSnackbarObj({
          isShowSnackbar: true,
          message: 'Detail submitted Successfully',
          variant: 'success',
        })
        setSelectedSupplier(null);
        setSupplierList([]);
        setProductData([]);
        setProductList([])
        setClear(null);
      }
    }
  }

  const setProductName = (e, option, reason) => {
    if (reason === 'selectOption') {
      option.quantity = 0
      setProductData([...productData, option])
      setClear(null);
    }
  }

  const handleDeleteProduct = (row) => {
    let pdata = (productData || []).filter(item => item._id == row._id);
    setProductData(pdata);
  }

  const handleTotal = () => {
    let total = 0;
    productData.map(row => {
      total += row.quantity * row.unitPrice
    })
    return total ? total.toFixed(2) : total;
  }

  const handleProductNameChange = async (event, value, reason) => {
    if (reason === 'input') {
      if (event.target.value === "") {
        setProductList([])
      }
      else {
        let response = await api.get("product", { searchName: event.target.value })
        if (response) {
          setProductList(response.data.data)
        }
      }
    }
  }

  const handleQtyChange = (e, rowIndex) => {
    let productListTemp = [...productData];
    productListTemp[rowIndex]["quantity"] = parseInt(e.target.value) || productListTemp[rowIndex]["quantity"];
    setProductData(productListTemp);
  }

  const handlePriceChange = (e, rowIndex) => {
    let productListTemp = [...productData];
    productListTemp[rowIndex]["unitPrice"] = parseInt(e.target.value) || productListTemp[rowIndex]["unitPrice"]
    setProductData(productListTemp)
  }

  return (
    <div>
      <Card className='poCard' disablepadding="true">
        <CardContent>
          <div className='billingAddresses'>
            <InfoInput
              nameList={supplierList}
              selectedName={selectedSupplier}
              onSelectOption={setSupplierName}
              onInputChange={handleSupplierNameChange}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }} className='mt-0'>
            <TableCell align="left">
              <Autocomplete
                freeSolo
                disablePortal
                id="searchproduct"
                name="searchproduct"
                value={clear}
                isOptionEqualToValue={(option, newValue) => {
                  return option._id === newValue._id;
                }}
                filterOptions={(x) => x}
                getOptionLabel={(option) => `${option.name} - ${option.unitPrice}`}
                options={productList}
                onChange={(e, option, reason) => setProductName(e, option, reason)}
                onInputChange={(e, value, reason) => handleProductNameChange(e, value, reason)}
                sx={{ width: 200 }}
                renderInput={(params) => <TextField {...params} label="Select Products" />}
              />

            </TableCell>
          </div>
          <TableContainer className='shadow-none' component={Paper}>
            <Table sx={{ minWidth: 650, overflowX: 'auto' }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Sr. NO</TableCell>
                  <TableCell align="left">Description</TableCell>
                  <TableCell align="left">Unit Price</TableCell>
                  <TableCell align="left">Qty</TableCell>
                  <TableCell align="left">HSN</TableCell>
                  <TableCell align="left">SGST</TableCell>
                  <TableCell align="left">CGST</TableCell>
                  <TableCell align="left">IGST</TableCell>
                  <TableCell align="left">Tax Amount</TableCell>
                  <TableCell align="left">Total Amount</TableCell>
                  <TableCell align="left">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {((productData || []).map((row, i) => (
                  <TableRow key={`${row._id} - ${i}`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="left">
                      {i + 1}
                    </TableCell>
                    <TableCell align="left">
                      {row.name}
                    </TableCell>
                    <TableCell align="left"><TextField variant="standard" defaultValue={row.unitPrice} name="purchasePrice" onChange={(event) => handlePriceChange(event, i)} /></TableCell>
                    <TableCell align="left"><TextField variant="standard" defaultValue={row.quantity || 0} name="quantity" onChange={(event) => handleQtyChange(event, i)} /></TableCell>
                    <TableCell align="left">{row.hsn ? row.hsn : "-"}</TableCell>
                    <TableCell align="left">{row.gst / 2}</TableCell>
                    <TableCell align="left">{row.gst / 2}</TableCell>
                    <TableCell align="left">0</TableCell>
                    <TableCell align="left">{(row.quantity * row.unitPrice * row.gst / 100).toFixed(2)}</TableCell>
                    <TableCell align="left">{(row.quantity * row.unitPrice).toFixed(2)}</TableCell>

                    <TableCell align="center">
                      <div className='d-flex justify-content-center'>
                        <Button color='danger' onClick={() => handleDeleteProduct(row)}>
                          <DeleteIcon />
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                )))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={9} align="right">
                    <p className='footprice'>Total Items: {productData.length}</p>
                  </TableCell>
                  <TableCell colSpan={6} align="left">
                    <p className='footprice'>Grand Total:  <CurrencyRupeeIcon />{handleTotal()}</p>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </CardContent>
        <CardActions className='cardActions justify-content-center'>
          <Button className='submitBtn' variant='contained' onClick={() => submitPurchaseData()}>Submit</Button>
        </CardActions>
      </Card>
      {snackbarObj.isShowSnackbar && (
        <Snackbar open={snackbarObj.isShowSnackbar} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() => setSnackbarObj({ isShowSnackbar: false, message: '', variant: '' })}>
          <Alert onClose={() => setSnackbarObj({ isShowSnackbar: false, message: '', variant: '' })} severity={snackbarObj.variant} sx={{ width: '100%' }}>
            {snackbarObj.message}
          </Alert>
        </Snackbar>)}
    </div>
  )
}
