import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Link } from "react-router-dom";
import './purchaseList.scss'
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import PropTypes from 'prop-types';
import Api from '../../service/Api';
import Filter from '../common/filter';
import SearchBar from '../common/search';
import logo from '../../assets/image/logo.png'
import { Box } from '@mui/material';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import moment from "moment";

const api = new Api()

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};


export default function PurchaseList() {

    const [selectedClient, setSelectedClient] = useState([]);
    const [clientProducts, setClientProducts] = useState([]);
    const [purchaseData, setPurchaseData] = useState([]);
    const [page, setPage] = React.useState(0);
    const [profile, setProfile] = useState(null);
    const [poNumberData,setPONumberData] = useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, purchaseData.length - page * rowsPerPage);
    useEffect(() => {
        getPurchaseOrders();
        getProfile();
    }, [])

    const getPurchaseOrders = async () => {
        let response = await api.get('purchaseorder');
        if (response) {
            setPurchaseData(response.data.data)
        }
    }

    const getProfile = async () => {
        let response = await api.get("profile",);
        setProfile(response.data.data)
    }

    const searchPurchaseOrder = async (event) => {
        let response = await api.get('purchaseorder', { searchName: event.target.value });
        if (response.status == 200) {
            setPurchaseData(response.data.data);
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const generatePDF = () => {
        html2canvas(document.getElementById("capture"), {
            onclone: function (clonedDoc) {
                clonedDoc.getElementById('capture').style.display = 'block';
            }
        })
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF({
                    // orientation: 'landscape',
                });
                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                // pdf.save('download.pdf');
                pdf.save(moment(selectedClient.createdAt).utc().format('YYYY-MM-DD') + '_PO'+'_'+poNumberData + '.pdf');

            });


    };

    const handleSelectChange = (data) => {
        setPONumberData(data.poNumber)
        setSelectedClient(data.supplierData)
        setClientProducts(data.products)
        return selectedClient;
    };

    const handleTotalAmountIfZero = () => {
        let total = 0;

        purchaseData.map((item) => {
            item.products.map((item) => {
                total += ((item.unitPrice * item.quantity * item.gst) / 100 + (item.unitPrice * item.quantity))
            })
        })
        return total ? total.toFixed(2) : 0;
    }

    const handleTotalAmount = () => {
        let total = 0;
        clientProducts.map((e) => {
            total += ((e.unitPrice * e.quantity * e.gst) / 100 + (e.unitPrice * e.quantity))
        })
        return total ? total.toFixed(2) : 0;
    }

    useEffect(() => {
        if (selectedClient != 0 && selectedClient != null) {
            generatePDF();

        }
    }, [selectedClient]);

    return (
        <div>
            <Card className='clientCard' >
                <CardContent>
                    <div className='clientBox'>
                        <div className='iconBox'>
                            <Filter />
                            <SearchBar searchData={searchPurchaseOrder} />
                        </div>
                        <Link to="/create-purchase">
                            <Fab color="primary" aria-label="add" size="medium">
                                <AddIcon />
                            </Fab>
                        </Link>
                    </div>
                    <TableContainer className='shadow-none' component={Paper}>
                        <Table sx={{ minWidth: 650, overflowX: 'auto' }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>P.O Number</TableCell>
                                    <TableCell align="left">Date</TableCell>
                                    <TableCell align="left">Client Name</TableCell>
                                    <TableCell align="left">Items</TableCell>
                                    <TableCell align="left">Amount</TableCell>
                                    <TableCell align="left">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                    ? purchaseData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : purchaseData
                                ).map((row) => (
                                    <TableRow key={row._id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="left"> 
                                            {row.poNumber}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row?.createdAt ? new Date(row.createdAt).toDateString() : '-'}
                                        </TableCell>
                                        <TableCell align="left">{row?.supplierData?.name || '-'}</TableCell>
                                        <TableCell align="left">{row?.totalOrderedQuantity || 0}</TableCell>
                                        <TableCell align="left">{row?.totalOrderedAmount || handleTotalAmountIfZero()}</TableCell>
                                        <TableCell align="left">
                                            <Button>
                                                <FileDownloadOutlinedIcon onClick={() => handleSelectChange(row)} />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        component="div"
                        count={purchaseData?.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </CardContent>
            </Card>
            {/* _______________________________PDF_____________________________________________ */}
            <div id="capture" >

                <div className='container invoicePdf' >
                    <div className='row py-3'>
                        <div className='col-sm-3'>
                            <img src={logo} className='logo' />
                        </div>
                        <div className='col-sm-3' ></div>
                        <div className='col-sm-4'></div>
                    </div>

                    <Box sx={{ overflow: "auto" }}>
                        <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>

                            <Table sx={{ minWidth: 650 }} aria-label="simple table" style={{ borderBottom: 'transparent' }}>

                                <TableRow className='billingAddresses' >

                                    <TableCell colSpan={6}>

                                        {(profile && profile.userProfile && profile.userProfile.firmDetails) &&

                                            <div>
                                                <p><span>Sold By :</span></p>
                                                <p>{profile.userProfile.firmDetails.firmName}</p>
                                                <p>{profile.userProfile.address.line1}</p>
                                                <p>{profile.userProfile.address.line2}</p>
                                                <p><span>PAN No.</span>{profile.userProfile.firmDetails.panNo}</p>
                                                <p><span>GST Registration No.:</span> {profile.userProfile.firmDetails.gstIn}</p>
                                            </div>

                                        }



                                    </TableCell>
                                    <TableCell colSpan={6} align="right" className='billingSection'>
                                        {purchaseData &&
                                            <div  >
                                                <p><span>Billing Address:</span></p>
                                                <p>{selectedClient.name}</p>
                                                <p><span>Mobile No:</span>{selectedClient.phoneNo}</p>
                                                <p><span>Email Id:</span>{selectedClient.email}</p>
                                            </div>}
                                    </TableCell>

                                </TableRow>

                            </Table>
                        </Box></Box>


                    <div className='mt-0' >
                        <Box sx={{ overflow: "auto" }}>
                            <Box sx={{ width: "100%", display: "table" }}>
                                <Table className='shadow-none productTable' style={{ borderTop: '1px solid rgba(205, 201, 201, 0.588)', borderBottom: '1px solid rgba(205, 201, 201, 0.588)' }}>
                                    <TableHead>
                                        <TableRow >
                                            <TableCell align="left" className='TableBrder__tableHead'>Sr. No.</TableCell>
                                            <TableCell align="left" className='TableBrder__tableHead'>Description</TableCell>
                                            <TableCell align="left" className='TableBrder__tableHead'>Unit Price</TableCell>
                                            <TableCell align="left" className='TableBrder__tableHead'>Qty</TableCell>
                                            <TableCell align="left" className='TableBrder__tableHead'>Net Amount</TableCell>
                                            <TableCell align="left" className='TableBrder__tableHead'>HSN</TableCell>
                                            <TableCell align="left" className='TableBrder__tableHead'>SGST</TableCell>
                                            <TableCell align="left" className='TableBrder__tableHead'>CGST</TableCell>
                                            <TableCell align="left" className='TableBrder__tableHead'>IGST</TableCell>
                                            <TableCell align="left" className='TableBrder__tableHead'>Tax Amount</TableCell>
                                            <TableCell align="left" className='TableBrder__tableHead'>Total Amount</TableCell>


                                        </TableRow>
                                    </TableHead>
                                    <TableBody >
                                        {(clientProducts || []).map((item, index) => (

                                            <TableRow key={index}>


                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{item && item.name ? item.name : "xyz"}</TableCell>
                                                <TableCell>{item && item.purchasePrice ? item.purchasePrice : 0}</TableCell>
                                                <TableCell>{item && item.quantity ? item.quantity : null}</TableCell>

                                                <TableCell>{item && item.purchasePrice ? item.purchasePrice * item.quantity : 0}</TableCell>
                                                <TableCell>{item && item.hsn ? item.hsn : 0}</TableCell>
                                                <TableCell>{item && item.gst ? item.gst / 2 : 0}</TableCell>
                                                <TableCell>{item && item.gst ? item.gst / 2 : 0}</TableCell>
                                                <TableCell>0</TableCell>
                                                <TableCell>{item && item.purchasePrice ? ((item.purchasePrice * item.quantity * item.gst) / 100).toFixed(2) : 0}</TableCell>
                                                <TableCell>{item && item.purchasePrice ? (item.purchasePrice * item.quantity).toFixed(2) : 0}</TableCell>

                                            </TableRow>
                                        ))}


                                        <TableRow>
                                        </TableRow>
                                        <TableCell className='footprice' colSpan={10} align="right" >Total Items: {clientProducts.length}</TableCell>
                                        <TableCell className='footprice' colSpan={1} align="right">Grand Total:<CurrencyRupeeOutlinedIcon fontSize='small' />{handleTotalAmount()}</TableCell>
                                        <TableCell></TableCell>

                                    </TableBody>
                                </Table>
                            </Box>
                        </Box>

                    </div>




                </div>
            </div>



        </div>
    )
}
