import React, { useState, useEffect } from 'react'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import './setting.scss'
import Api from '../../service/Api';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Sign from '../../assets/image/dummyImg.png'
const api = new Api();

export default function Profile() {
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    const panRegex = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
    const [firmName, setFirmName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [gst, setGst] = useState("");
    const [pan, setPan] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [pincode, setPincode] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");

    const handleSubmitClient = async () => {
        let body = {
            firmDetails: {
                firmName,
                contactNo: phoneNo,
                gstIn: gst,
                panNo: pan,
            },
            address: {
                line1: address1,
                line2: address2,
                pincode,
                city,
                state,
                country
            }
        }

        let response = await api.post(`profile`, body)
        if (response) {
            getProfile();
        }
    }

    const getProfile = async () => {
        let response = await api.get(`profile`);
        if (response && response.data.data) {
            let profile = response.data.data;
            setFirmName(profile.userProfile.firmDetails.firmName);
            setEmail(profile.email);
            setPhoneNo(profile.userProfile.firmDetails.contactNo);
            setGst(profile.userProfile.firmDetails.gstIn);
            setPan(profile.userProfile.firmDetails.panNo);
            setAddress1(profile.userProfile.address.line1);
            setAddress2(profile.userProfile.address.line2);
            setPincode(profile.userProfile.address.pincode);
            setCity(profile.userProfile.address.city);
            setState(profile.userProfile.address.state);
            setCountry(profile.userProfile.address.country);
        }
    }

    useEffect(() => {
        getProfile()
    }, [])

    return (
        <div className='setting'>
            <div className='row justify-content-center'>
                <div className='col-md-12'>
                    <Card>
                        <CardContent>
                            {/* <h5 className='pageHeading text-left'>Firm Details</h5>
                            <Divider /> */}
                            <div className='row '>
                                <div className='col-md-4'>
                                    <TextField className='inputBox ms-1' id="name" label="Name" variant="standard" value={firmName} onChange={(e) => setFirmName(e.target.value)} />
                                </div>
                                <div className='col-md-4'>
                                    <TextField disabled={true} className='inputBox' id="email" label="Email" value={email} variant="standard" />
                                </div>
                                <div className='col-md-4'>
                                    <TextField inputProps={{ maxLength: 10, minLength: 10 }} className='inputBox' id="contactNo" label="Contact No" variant="standard" value={phoneNo} onChange={(e) => setPhoneNo(e.target.value.replace(/[^0-9]/g, ""))} />
                                </div>
                                <div className='col-md-4'>
                                    <TextField className='inputBox ms-1' id="Firm_name" label="Business Name" variant="standard" value={firmName} onChange={(e) => setFirmName(e.target.value)} />
                                </div>
                                <div className='col-md-4'>
                                    <FormControl variant="standard" sx={{ m: 0, minWidth: '100%' }}>
                                        <InputLabel id="demo-simple-select-standard-label">Business Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={age}
                                            onChange={handleChange}
                                            label="Age"
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value={10}>Private Limited</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className='col-md-4'>
                                    <TextField error={!pan && !panRegex.test(pan)} className='inputBox' id="pan" label="Pan No" variant="standard" value={pan} onChange={(e) => setPan(e.target.value.toUpperCase())} />
                                </div>
                                <div className='col-md-4'>
                                    <TextField error={!gst && !gstRegex.test(gst)} className='inputBox' id="GST Number" label="GST Number" variant="standard" value={gst} onChange={(e) => setGst(e.target.value.toUpperCase())} />
                                </div>
                                <div className='col-md-4'>
                                    <TextField error={!gst && !gstRegex.test(gst)} className='inputBox' id="GST Number" label="Website" variant="standard" value='www.dailever.com' onChange={(e) => setGst(e.target.value.toUpperCase())} />
                                </div>
                                <h6 className='bold'>Address</h6>
                                <div className='col-md-6'>
                                    <TextField multiline minRows={2} maxRows={4} className='inputBox' id="block" placeholder='Address Line 1' variant="standard" value={address1} onChange={(e) => setAddress1(e.target.value)} />
                                </div>
                                <div className='col-md-6'>
                                    <TextField multiline minRows={2} maxRows={4} className='inputBox' id="block" placeholder='Address Line 2' variant="standard" value={address2} onChange={(e) => setAddress2(e.target.value)} />
                                </div>
                                <div className="col-md-3">
                                    <TextField className='inputBox' id="pincode" label="Pin Code" variant="standard" value={pincode} onChange={(e) => setPincode(e.target.value)} />
                                </div>
                                <div className="col-md-3">
                                    <TextField className='inputBox' id="city" label="City" variant="standard" value={city} onChange={(e) => setCity(e.target.value)} />
                                </div>
                                <div className="col-md-3">
                                    <TextField className='inputBox' id="state" label="State" variant="standard" value={state} onChange={(e) => setState(e.target.value)} />
                                </div>
                                <div className="col-md-3">
                                    <TextField className='inputBox' id="country" label="Country" variant="standard" value={country} onChange={(e) => setCountry(e.target.value)} />
                                </div>
                                <div className="col-md-4">
                                    <h2 className='sign'>Upload Signature</h2>
                                    <div className='signBox'>
                                        <IconButton color="primary" aria-label="upload picture" component="label">
                                            <input hidden accept="image/*" type="file" />
                                            <PhotoCamera />
                                        </IconButton>
                                        <img className='ms-5 img-fluid' src={Sign} alt="Sign"/>
                                    </div>
                                </div>

                            </div>
                        </CardContent>
                        <CardActions className='justify-content-center mb-3'>
                            <Button className='loginBtn' variant='contained' onClick={handleSubmitClient}>Submit </Button>
                        </CardActions>
                    </Card>
                </div>
            </div>
        </div>
    )
}
