/* eslint-disable no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */

import { Navigate } from 'react-router-dom';
import { getUser } from '../service/Auth';

export const isLoggedIn = () => (!!getUser());


export const PrivateRoute = ({ children, ...rest }) => {
    return isLoggedIn() ? children : <Navigate to="/login" />
};


export const PublicRoute = ({ children, ...rest }) => {
    return isLoggedIn() ? <Navigate to="/" /> : children
};