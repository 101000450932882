/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import Divider from '@mui/material/Divider';
import Api from '../../service/Api'
import { useNavigate, useParams } from 'react-router-dom';
import { AddclientSchema } from '../schema/Addclientschema';
import { useFormik } from 'formik';
const api = new Api()
const initialValues = {
    name: "",
    firmName: "",
    email: "",
    phoneNo: "",
    city: "",
    country: "",
    line1: "",
    line2: "",
    pincode: "",
    state: "",
    gstIn: "",
    panNo: "",
    websiteUrl: "",
    hasGst: false
};

export default function AddClient() {
    const dataFetchedRef = useRef(false);
    const navigate = useNavigate();
    const params = useParams();
    const { clientId } = params;
    const [checked, setChecked] = useState(false);
    const [text, setText] = useState("");
    const [hasGst, setHasGst] = useState(false);
    const [isEditPage, setIsEditPage] = useState(false);
    const { values, errors, touched, handleChange, handleSubmit, setValues } = useFormik({
        initialValues: initialValues,
        validationSchema: AddclientSchema,
        onSubmit: () => {
            if (clientId) {
                postFetchData();
            } else {
                handleSubmitClient();
            }

        }

    })
    const handleSubmitClient = async () => {
        try {
            let body = {
                name: values.name,
                email: values.email,
                phoneNo: values.phoneNo,
                hasGst: values.hasGst,
                address: {
                    line1: values.line1,
                    line2: values.line2,
                    pincode: values.pincode,
                    city: values.city,
                    state: values.state,
                    country: values.country,
                },
                gstDetails: {
                    firmName: values.firmName,
                    gstIn: values.gstIn,
                    panNo: values.panNo,
                    websiteUrl: values.websiteUrl
                }
            }
            let response = await api.put('client', body)
            if (response) {
                navigate('/customers')
            }
        }
        catch (err) {
            console.error(err);
        }

    }

    async function editClientData() {
        try {
            let response = await api.get('client', { clientId: clientId })
            console.log('Response', response)
            if (response && response.data.data.length > 0) {
                setIsEditPage(true);
                // console.log('Response is', response.data.data)
                const defaultValue = {
                    name: response?.data?.data[0]?.name,
                    email: response?.data?.data[0]?.email,
                    phoneNo: response?.data?.data[0]?.phoneNo,
                    hasGst: response?.data?.data[0]?.hasGst,
                    gstIn: response?.data?.data[0]?.gstDetails?.gstIn,
                    firmName: response?.data?.data[0]?.gstDetails?.firmName,
                    websiteUrl: response?.data?.data[0]?.gstDetails?.websiteUrl,
                    panNo: response?.data?.data[0]?.gstDetails?.panNo,
                    line1: response?.data?.data[0]?.address?.line1,
                    line2: response?.data?.data[0]?.address?.line2,
                    city: response?.data?.data[0]?.address?.city,
                    state: response?.data?.data[0]?.address?.state,
                    pincode: response?.data?.data[0]?.address?.pincode,
                    country: response?.data?.data[0]?.address?.country,
                }
                setValues(defaultValue)//it replace the whole object
                setChecked(response?.data?.data[0]?.hasGst)
            }
        } catch (error) {
            console.error(error);
        }
    }


    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        if (clientId && clientId !== "new") {
            editClientData()
        }

    }, [])

    async function postFetchData() {
        try {
            console.log("Add Client Post Values ", values)

            const defaultValue = {
                firmName: values?.firmName,
                gstIn: values?.gstIn,
                panNo: values?.panNo,
                websiteUrl: values?.websiteUrl,
                line1: values?.line1,
                line2: values?.line2,
                state: values?.state,
                city: values?.city,
                country: values?.country,
                pincode: values?.pincode,

            }

            let address = {
                line1: defaultValue?.line1,
                line2: defaultValue?.line2,
                pincode: defaultValue?.pincode,
                state: defaultValue?.state,
                city: defaultValue?.city,
                country: defaultValue?.country
            }
            let gstDetails = {
                firmName: defaultValue?.firmName,
                gstIn: defaultValue?.gstIn,
                panNo: defaultValue?.panNo,
                websiteUrl: defaultValue?.websiteUrl
            }
            const body = {
                name: values.name,
                email: values.email,
                phoneNo: values.phoneNo,
                hasGst: values.hasGst,
                gstDetails,
                address,
            }
            let response = await api.post(`client/${clientId}`, body)
            if (response.status === 200) {
                console.log(response)
                navigate("/customers")

            }
        }
        catch (err) {
            console.error(err);
        }
    }

    return (
        <div className='addClient'>
            <div className='row justify-content-center'>
                <div className='col-md-12'>
                    <Card>
                        <CardContent>
                            <form onSubmit={handleSubmit}>
                                <div className='row '>
                                    <div className='col-md-4'>
                                        <TextField className='inputBox ms-1' id="name" name='name' label="Name" value={values.name} onChange={handleChange} variant="standard" />
                                        {/* {errors.name && touched.name ? (<p className='form-error'>{errors.name}</p>):null} */}
                                        {<p className='form-error'>{errors.name}</p>}

                                    </div>
                                    <div className='col-md-4'>
                                        <TextField className='inputBox' id="email" name='email' label="Email" value={values.email} onChange={handleChange} variant="standard" />
                                        {<p className='form-error'>{errors.email}</p>}
                                    </div>
                                    <div className='col-md-4'>
                                        <TextField className='inputBox' id="contactNo" name='phoneNo' label="Mobile No" value={values.phoneNo} onChange={handleChange} variant="standard" />
                                        {<p className='form-error'>{errors.phoneNo}</p>}
                                    </div>
                                    <div className='col-md-12'>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        type="checkbox"
                                                        checked={checked}
                                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                                        name="hasGst"
                                                        onChange={() => {
                                                            setChecked(!checked);
                                                            setValues((prevValues) => ({
                                                                ...prevValues,
                                                                hasGst: !checked,
                                                            }));
                                                        }}
                                                    />
                                                }
                                                label="Have GST"
                                            />

                                        </FormGroup>
                                    </div>
                                    <div className='col-md-3'>
                                        <TextField className='inputBox ms-1' id="firmName" name='firmName' type="text" disabled={!checked} value={checked ? values.firmName : ""} onChange={handleChange} label="Firm Name" variant="standard" />
                                        {checked && <p className='form-error'>{errors.firmName}</p>}
                                    </div>
                                    <div className='col-md-3'>
                                        <TextField className='inputBox' id="gstIn" label="GST Number" type="text" name="gstIn" value={checked ? values.gstIn : ""} onChange={handleChange} variant="standard" disabled={!checked} />
                                        {checked && <p className='form-error' >{errors.gstIn} </p>}
                                    </div>
                                    <div className='col-md-3'>
                                        <TextField className='inputBox' id="panNo" label="PAN Number" type="text" name="panNo" value={checked ? values.panNo : ""} onChange={handleChange} variant="standard" disabled={!checked} />
                                        {checked && <p className='form-error' >{errors.panNo}</p>}
                                    </div>
                                    <div className='col-md-3'>
                                        <TextField className='inputBox' id="websiteUrl" name='websiteUrl' disabled={!checked} value={checked ? values.websiteUrl : ""} onChange={handleChange} label="Website Url" variant="standard" />
                                        {checked && <p className='form-error'>{errors.websiteUrl}</p>}
                                    </div>
                                    <h6 className='bold'>Address</h6>
                                    <div className='col-md-6'>
                                        <TextField multiline minRows={1} maxRows={2} className='inputBox' name='line1' value={values.line1} onChange={handleChange} placeholder='Address Line 1' variant="standard" />
                                        {<p className='form-error'>{errors.line1}</p>}
                                    </div>
                                    <div className='col-md-6'>
                                        <TextField multiline minRows={1} maxRows={2} className='inputBox' name='line2' value={values.line2} onChange={handleChange} placeholder='Address Line 2' variant="standard" />
                                    </div>
                                    <div className="col-md-3">
                                        <TextField className='inputBox' id="pincode" label="Pin Code" name='pincode' value={values.pincode} onChange={handleChange} variant="standard" />
                                        {<p className='form-error'>{errors.pincode}</p>}
                                    </div>
                                    <div className="col-md-3">
                                        <TextField className='inputBox' id="city" label="City" name='city' value={values.city} onChange={handleChange} variant="standard" />
                                        {<p className='form-error'>{errors.city}</p>}
                                    </div>
                                    <div className="col-md-3">
                                        <TextField className='inputBox' id="state" label="State" name='state' value={values.state} onChange={handleChange} variant="standard" />
                                        {<p className='form-error'>{errors.state}</p>}
                                    </div>
                                    <div className="col-md-3">
                                        <TextField className='inputBox' id="country" label="Country" name='country' value={values.country} onChange={handleChange} variant="standard" />
                                        {<p className='form-error'>{errors.country}</p>}
                                    </div>

                                </div>
                                <CardActions className='justify-content-center'>
                                    <Button className='loginBtn' variant='contained' type="submit" >{clientId ? "Update" : "Add"}</Button>
                                </CardActions>
                            </form>
                        </CardContent>

                    </Card>
                </div>
            </div>
        </div>
    )
}
