import * as Yup from 'yup';
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
export const AddEmployeeSchema = Yup.object({
    name:Yup.string().min(2).max(25).required("Please Enter Your Name"),
    email:Yup.string().email().required("Please Enter Your email"),
    phoneNo: Yup.string().matches(phoneRegExp, 'Phone number must have Ten numbers'),
    line1:Yup.string().required("Please enter address"),
    line2:Yup.string().required("Please enter address"),
    state:Yup.string().required("Enter State Name"),
    city:Yup.string().required("Enter City Name"),
    country:Yup.string().required("Enter City Name"),
    pincode:Yup.string().required("Please enter Pin code"),
    password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .matches(/[a-zA-Z]/, "Password must contain both letters and numbers")
    .required("Please enter your password"),
});