
import Api from './Api';

const api = new Api();

export const login = async (data) => {
    try {
        const response = await api.post("login", data);
        if (response.data && response.data.token) {
            localStorage.setItem("token", response.data.token);
            return true;
        } else {
            return false;
        }
    }
    catch (e) {
        return false;
    }
}

export const register = async (data) => {
    try {
        await api.put("register", data);
    }
    catch (e) {
        return false;
    }
}

export const logout = () => {
    localStorage.removeItem("token");
}

export const getUser = () => {
    return localStorage.getItem("token");
}
