import axios from 'axios';
class Api {
    constructor () {
        // eslint-disable-next-line no-undef
        this.baseUrl = process.env.REACT_APP_API_URL;
    }

    async post(url, data, headers = {}) {
        try {
            url = `${this.baseUrl}${url}`
            return await axios.post(url, data, { headers });
        } catch (e) {
            console.log(e)
            throw e;
        }

    }

    async patch(url, data, headers = {}) {
        try {
            url = `${this.baseUrl}${url}`
            return await axios.post(url, data, { headers });
        } catch (e) {
            console.log(e)
            throw e;
        }
    }

    async delete(url, params = {}, headers = {}) {
        try {
            url = `${this.baseUrl}${url}`
            return await axios.delete(url, {params, headers });
        } catch (e) {
            console.log(e)
            throw e;
        }
    }

    async get(url, params = {}, headers = {}) {
        try {
            url = `${this.baseUrl}${url}`
            return await axios.get(url, { params, headers });
        } catch (e) {
            console.log(e)
            throw e;
        }
    }

    async put(url,data, params = {} ,headers = {}) {
        try {
            url = `${this.baseUrl}${url}`
            return await axios.put(url, data, {params , headers} );
        } catch (e) {
            console.log(e)
            throw e;
        }
    }
}

export default Api;