import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import './expense.scss';
import '../../style/variables.scss'
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import Api from '../../service/Api'
import Filter from '../common/filter';
import SearchBar from '../common/search';
import moment from 'moment';

const api = new Api()

export default function PurchaseExpenses() {

    const [expenseData, setExpenseData] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        getExpense()
    }, [])

    const getExpense = async () => {
        let response = await api.get('purchase/expense');
        if (response) {
            setExpenseData(response.data.data)
        }
    }

    const deleteExpense = async (row) => {
        let response = await api.delete(`purchase/expense/${row._id}`);
        if (response) {
            getExpense();
        }
    }

    const searchData = async (event) => {
        let response = await api.get('purchase/expense', { searchName: event.target.value });
        if (response) {
            setExpenseData(response.data.data);
        }
    }

    return (
        <div>
            <Card className='expenseCard' disablepadding="true">
                <CardContent>
                    <div className='expenseBox'>
                        <Filter />
                        <div className='iconBox'>
                            <SearchBar searchData={searchData} />
                            <Link to="/purchase/expense/new">
                                <Fab color="primary" aria-label="add" size="medium">
                                    <AddIcon />
                                </Fab>
                            </Link>
                        </div>
                    </div>

                    <TableContainer className='shadow-none' component={Paper}>
                        <Table className='expenseData' sx={{ overflowX: 'scroll' }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sr. No</TableCell>
                                    <TableCell>Supplier Name</TableCell>
                                    <TableCell align="left">Bill No</TableCell>
                                    <TableCell align="left">Quantity</TableCell>
                                    <TableCell align="left">Amount</TableCell>
                                    <TableCell align="left">Bill Date</TableCell>
                                    <TableCell align="left">Created Date</TableCell>
                                    <TableCell align="center">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {((expenseData || []).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                    <TableRow key={row._id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="left">{i + 1}</TableCell>
                                        <TableCell align="left">{row.supplierName}</TableCell>
                                        <TableCell align="left">{row.billNumber || 'NA'}</TableCell>
                                        <TableCell align="left">{row.totalQuantity}</TableCell>
                                        <TableCell align="left">{row.totalAmount}</TableCell>
                                        <TableCell align="left">{moment(row.billDate).format("L")}</TableCell>
                                        <TableCell align="left">{moment(row.createdAt).format("L")}</TableCell>

                                        <TableCell align="center">
                                            <div className='d-flex justify-content-center'>
                                                <Button color='danger' onClick={() => deleteExpense(row)}>
                                                    <DeleteIcon />
                                                </Button>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        component="div"
                        count={expenseData.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[5,10,25,50,100]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </CardContent>

            </Card>
        </div>
    )
}

