import React from 'react';
import Form from 'react-bootstrap/Form';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';

export default function InfoInput({ grnBool, nameList, fieldsInfo, selectedName, onSelectOption, onInputChange }) {
  return (
    <div>
      <div className='row'>
        <div className='col-md-3'>
          {grnBool ? (   
            <Form>
               <Autocomplete className='p-0'
                  id="searchname"
                  name="searchname"
                  value={selectedName}
                  filterOptions={(x) => x}
                  getOptionLabel={(option) => `${option.poNumber}`}
                  options={nameList}
                  onChange={(e, option, reason) => onSelectOption(e, option, reason)}
                  onInputChange={(e, value, reason) => onInputChange(e, value, reason)}
                  renderInput={(params) => <TextField  className="mb-3" {...params} label="PO Number" />}
                />
            </Form>
          ) : (
            <Form>
              <Form.Group className="mb-3">
                <Autocomplete
                  freeSolo
                  disablePortal
                  id="searchname"
                  name="searchname"
                  value={selectedName }
                  isOptionEqualToValue={(option, newValue) => {
                    return option._id === newValue._id;
                  }}
                  filterOptions={(x) => x}
                  getOptionLabel={(option) => `${option.name}`}
                  options={nameList}
                  onChange={(e, option, reason) => onSelectOption(e, option, reason)}
                  onInputChange={(e, value, reason) => onInputChange(e, value, reason)}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                      <Form.Control type="text" placeholder="Name " {...params.inputProps} />
                    </div>
                  )}
                />
              </Form.Group>
            </Form>
          )}
        </div>
        <div className='col-md-3'>
          <Form>
            <Form.Group className="mb-3" >
              <Form.Control type="text"  placeholder="Business Name " value={selectedName?.gstDetails?.firmName || '' || selectedName?.supplierData?.gstDetails?.firmName} />
            </Form.Group>
          </Form>
        </div>
        <div className='col-md-3'>
          <Form>
            <Form.Group className="mb-3" >
              <Form.Control type="text"  placeholder="Contact No " value={selectedName?.phoneNo || '' || selectedName?.supplierData?.phoneNo } />
            </Form.Group>
          </Form>
        </div>
        <div className='col-md-3'>
          <Form>
            <Form.Group className="mb-3">
              <Form.Control type="email"  placeholder="Email Id " value={selectedName?.email || '' || selectedName?.supplierData?.email } />
            </Form.Group>
          </Form>
        </div>
        <div className='col-md-3'>
          <Form>
            <Form.Group className="mb-3" >
              <Form.Control type="text"  placeholder="Address Line 1 " value={selectedName?.address?.line1 || '' || selectedName?.supplierData?.address?.line1} />
            </Form.Group>
          </Form>
        </div>
        <div className='col-md-3'>
          <Form>
            <Form.Group className="mb-3" >
              <Form.Control type="text"  placeholder="Address Line 2 " value={selectedName?.address?.line2 || '' || selectedName?.supplierData?.address?.line2} />
            </Form.Group>
          </Form>
        </div>
        <div className='col-md-3'>
          <Form>
            <Form.Group className="mb-3" >
              <Form.Control type="number"  placeholder="Pin Code" value={selectedName?.address?.pinCode || '' || selectedName?.supplierData?.address?.pinCode} />
            </Form.Group>
          </Form>
        </div>
        <div className='col-md-3'>
          <Form>
            <Form.Group className="mb-3" >
              <Form.Control type="text"  placeholder="City " value={selectedName?.address?.city || '' || selectedName?.supplierData?.address?.city } />
            </Form.Group>
          </Form>
        </div>
        <div className='col-md-3'>
          <Form>
            <Form.Group className="mb-3" >
              <Form.Control type="text"  placeholder="State " value={selectedName?.address?.state || '' || selectedName?.supplierData?.address?.state} />
            </Form.Group>
          </Form>
        </div>
        <div className='col-md-3'>
          <Form>
            <Form.Group className="mb-3" >
              <Form.Control type="text"  placeholder="Country " value={selectedName?.address?.country || '' || selectedName?.supplierData?.address?.country } />
            </Form.Group>
          </Form>
        </div>
        <div className='col-md-3'>
          <Form>
            <Form.Group className="mb-3" >
              <Form.Control type="text"  placeholder="PAN No " value={selectedName?.gstDetails?.panNo || '' || selectedName?.supplierData?.gstDetails?.panNo} />
            </Form.Group>
          </Form>
        </div>
        <div className='col-md-3'>
          <Form>
            <Form.Group className="mb-3">
              <Form.Control type="text"  placeholder="GST Registration No " value={selectedName?.gstDetails?.gstIn || '' || selectedName?.supplierData?.gstDetails?.gstIn} />
            </Form.Group>
          </Form>
        </div>
        <div className='col-md-3'>
          <Form>
            <Form.Group className="mb-3" >
              <Form.Control type="text"  placeholder="Website  url "  />
            </Form.Group>
          </Form>
        </div>
      </div>
    </div>
  )
}
