import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import './employee.scss';
import '../../style/variables.scss'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import Api from '../../service/Api'
import Filter from '../common/filter';
import SearchBar from '../common/search';
const api = new Api()

export default function Employee() {

    const [employeeData, setEmployeeData] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        getEmployee()
    }, [])

    const getEmployee = async () => {
        let response = await api.get('employee');
        if (response) {
            setEmployeeData(response.data.data)
        }
    }

    const deleteEmployee = async (row) => {
        let response = await api.delete(`employee/${row._id}`);
        if (response) {
            getEmployee();
        }
    }

    const searchData = async (event) => {
        let response = await api.get('employee', { searchName: event.target.value });
        if (response) {
            setEmployeeData(response.data.data);
        }
    }

    return (
        <div>
            <Card className='employeeCard' disablepadding="true">
                <CardContent>
                    <div className='employeeBox'>
                        <Filter />
                        <div className='iconBox'>
                            <SearchBar searchData={searchData} />

                            <Link to="/employee/new">
                                <Fab color="primary" aria-label="add" size="medium">
                                    <AddIcon />
                                </Fab>
                            </Link>
                        </div>
                    </div>

                    <TableContainer className='shadow-none' component={Paper}>
                        <Table className='employeeData' sx={{ overflowX: 'scroll' }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sr. No</TableCell>
                                    <TableCell> Name</TableCell>
                                    <TableCell align="left">Mobile No.</TableCell>
                                    <TableCell align="left">Email</TableCell>
                                    <TableCell align="center">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {((employeeData || []).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                    <TableRow key={row._id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="left">
                                            {i + 1}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.name.toUpperCase()}
                                        </TableCell>
                                        <TableCell align="left">{row.phoneNo}</TableCell>
                                        <TableCell align="left">{row.email}</TableCell>

                                        <TableCell align="center">
                                            <div className='d-flex justify-content-center'>
                                                <Button color='danger' onClick={() => deleteEmployee(row)}>
                                                    <DeleteIcon />
                                                </Button>
                                                <Button color='secondary' component={Link} to={'/employee/' + row._id} >
                                                    <EditIcon />
                                                </Button>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )))}
                            </TableBody>
                            {/* <TableFooter className='tablePagination'>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        colSpan="13"
                                        count="2"
                                        rowsPerPage="3"
                                        page="10"

                                        onPageChange="2"
                                    // onRowsPerPageChange="4"
                                    // ActionsComponent="5"
                                    />
                                </TableRow>
                            </TableFooter> */}
                        </Table>
                    </TableContainer>
                    <TablePagination
                        component="div"
                        count={employeeData.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[5,10,25,50,100]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </CardContent>

            </Card>
        </div>
    )
}

