import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Link } from "react-router-dom";
import { register } from "../../service/Auth";
import Logo from '../../assets/image/logo.png';
import './customers.scss'

export default function CustomersSignup() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSingUp = () => {
    console.log(name, email, phoneNo, password, confirmPassword);
    const data = {
      name, 
      email, 
      phoneNo, 
      password, 
      confirmPassword
    }
    register(data);
    navigate("/login");
  }
  return (
    <div className='login'>
      <div className='row justify-content-center align-items-center h-100'>
        <div className='col-md-4'>
          <Card className='loginCard'>
            <CardContent>
              <img className='loginLogo' src={Logo} alt='logo' />
              <h3 className='loginHeading'>Create your Account</h3>
              <div className='row '>
                <div className='col-md-12'>
                  <TextField className='inputBox' id="name" label="Name" variant="standard" value={name} onChange={(e) => setName(e.target.value)} />
                </div>
                <div className='col-md-12'>
                  <TextField className='inputBox' id="email" label="Email" variant="standard" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className='col-md-12'>
                  <TextField className='inputBox' type="number" id="mobileNo" label="Mobile No" variant="standard" value={phoneNo} onChange={(e) => setPhoneNo(e.target.value)} />
                </div>
                <div className='col-md-12'>
                  <TextField className='inputBox' id="password" label="Password" variant="standard" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
                <div className='col-md-12'>
                  <TextField className='inputBox' id="password" label="Confirm Password" variant="standard" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                </div>
                <div className='col-md-6'>
                  <p>Already Have an Account</p>
                </div>
                <div className='col-md-6'>
                  <p className='text-end'>
                    <Link className='ms-1' to="/login">
                      Sign In
                    </Link>
                  </p>
                </div>
              </div>
            </CardContent>
            <CardActions className='justify-content-center'>
              <Button className='loginBtn' variant='contained' onClick={handleSingUp}>Sign Up</Button>
            </CardActions>
          </Card>
        </div>
      </div>
    </div>
  )
}
