import { React, useEffect, useState } from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import './product.scss';
import '../../style/variables.scss'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import Api from '../../service/Api'
import Filter from '../common/filter';
import SearchBar from '../common/search';
import TablePagination from '@mui/material/TablePagination';

const api = new Api()

export default function Product() {

    const [productData, setProductData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        getProduct()
    }, [])

    const getProduct = async () => {
        let response = await api.get('product');
        if (response) {
            setProductData(response.data.data)
        }
    }

    const deleteProduct = async (row) => {
        let response = await api.delete(`product/${row._id}`);
        if (response) {
            getProduct();
        }
    }

    const searchData = async (event) => {
        let response = await api.get('product', { searchName: event.target.value });
        if (response) {
            setProductData(response.data.data);
        }
    }

    return (
        <div>
            <Card className='productCard' disablepadding="true">
                <CardContent>
                    {/* <div className='clientBox'>
                        <h3 className='heading'>Product List</h3>
                        <div className='d-flex align-items-center'>
                            <Search className='me-3'>
                                <SearchIconWrapper>
                                    <SearchIcon />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search Client"
                                    inputProps={{ 'aria-label': 'search' }}
                                    onKeyUp={(event) => searchProduct(event)}
                                />
                            </Search>
                            <Tooltip title="Export Product">
                            <IconButton sx={{width:50}} color="primary" aria-label="upload picture" component="label">
                                <input hidden accept="image/*" type="file" />
                                <FileUploadIcon />
                            </IconButton>
                            </Tooltip>
                            <Tooltip title="Import Product">
                            <IconButton sx={{width:50,marginRight:1}} color="primary" aria-label="delete">
                                <DownloadIcon />
                            </IconButton>
                            </Tooltip>
                            <Link to="/product/new">
                                <Fab color="primary" aria-label="add" size="medium">
                                    <AddIcon />
                                </Fab>
                            </Link>
                        </div>
                    </div> */}
                    <div className='productBox'>
                        <Filter />
                        <div className='iconBox'>
                            <SearchBar searchData={searchData} />
                            <Link to="/product/new">
                                <Fab color="primary" aria-label="add" size="medium">
                                    <AddIcon />
                                </Fab>
                            </Link>
                        </div>
                    </div>

                    <TableContainer className='shadow-none' component={Paper}>
                        <Table className='productData' sx={{ overflowX: 'scroll' }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sr. No</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell align="left">Unit</TableCell>
                                    <TableCell align="left">Unit Price</TableCell>
                                    <TableCell align="left">HSN</TableCell>
                                    <TableCell align="left">GST</TableCell>
                                    <TableCell align="left">SP</TableCell>
                                    <TableCell align="left">PP</TableCell>
                                    <TableCell align="left">Barcode</TableCell>
                                    <TableCell align="center">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {((productData || []).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                    <TableRow key={row._id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="left">
                                            {i + 1}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.name.toUpperCase()}
                                        </TableCell>
                                        <TableCell align="left">{row.unit}</TableCell>
                                        <TableCell align="left">{row.unitPrice}</TableCell>
                                        <TableCell align="left">{row.hsn ? row.hsn : "-"}</TableCell>
                                        <TableCell align="left">{row.gst}</TableCell>
                                        <TableCell align="left">{row.sellPrice}</TableCell>
                                        <TableCell align="left">{row.purchasePrice}</TableCell>
                                        <TableCell align="left">{row.barcode}</TableCell>

                                        <TableCell align="center">
                                            <div className='d-flex justify-content-center'>
                                                <Button color='danger' onClick={() => deleteProduct(row)}>
                                                    <DeleteIcon />
                                                </Button>
                                                <Button color='secondary' component={Link} to={'/product/' + row._id} >
                                                    <EditIcon />
                                                </Button>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )))}
                            </TableBody>
                            {/* <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        colSpan="13"
                                        count="2"
                                        rowsPerPage="3"
                                        page="10"

                                        onPageChange="2"
                                    // onRowsPerPageChange="4"
                                    // ActionsComponent="5"
                                    />
                                </TableRow>
                            </TableFooter> */}
                        </Table>
                    </TableContainer>
                    <TablePagination
                        component="div"
                        count={productData.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[5,10,25,50,100]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </CardContent>
            </Card>
        </div>
    )
}

