import * as Yup from 'yup';


const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
const reggst = /^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/;
export const AddsupplierSchema = Yup.object({
    name: Yup.string().min(2).max(25).required("Please Enter Your Name"),
    email: Yup.string().email().required("Please Enter Your email"),
    phoneNo: Yup.string().matches(phoneRegExp, 'Phone number must have Ten numbers'),
    line1: Yup.string().required("Please enter address"),
    state: Yup.string().required("Enter State Name"),
    city: Yup.string().required("Enter City Name"),
    pincode: Yup.string().required("Please enter Pin code"),
    gstIn: Yup.string().required("Please enter GST number").matches(reggst, 'Please enter valid GST number'),
    panNo:  Yup.string().required("Please enter PAN number").matches(regpan, 'Please enter valid PAN number'),
    firmName: Yup.string().required("Please enter Business Name"),
});

export const AddproductSchema = Yup.object({
    name: Yup.string().min(2).max(25).required("Please Enter Your Name"),
    unit: Yup.string().required("Please Enter The Unit"),
    unitPrice: Yup.string().required("Please Enter The UnitPrice"),
    sku: Yup.string().required("Please enter SkuValue"),
    hsn: Yup.string().required("Please enter HSNValue"),
    gst: Yup.string().required("Please enter gst"),
    sellPrice: Yup.string().required("Please enter sellPrice"),
    purchasePrice: Yup.string().required("Please enter purchasePrice"),
    barcode: Yup.string().required("Please enter barcode"),

});

