import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Link } from "react-router-dom";
import './sales.scss'
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import PropTypes from 'prop-types';
import Api from '../../service/Api'
import InvoicePdf from './InvoicePdf';
import SearchBar from '../common/search'
import Filter from "../common/filter";
const api = new Api()


// eslint-disable-next-line no-unused-vars
const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: 2.5,
    },
}));


function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

export default function Sales() {
    
    const [salesData, setSalesData] = useState([]);
    // const [page, setPage] = React.useState(0);
    const [profile, setProfile] = useState(null);
    const [pdfData, setPdfData] = React.useState({});
    // const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, salesData.length - page * rowsPerPage);
    useEffect(() => {
        getSales();
        getProfile();
    }, [])

    const getSales = async () => {
        let response = await api.get('invoice');
        if (response) {
            setSalesData(response.data.data)
        }
    }

    const getProfile = async () => {
        let response = await api.get("profile",);
        setProfile(response.data.data)
    }

    const convertPdfData = (row) => {
        let total = 0;

        const products = (row.products || []).map(row => {
            row.netAmount = row.quantity * row.unitPrice;
            row.taxAmount = (row.unitPrice * row.quantity) / row.gst;
            row.totalAmount = row.netAmount + row.taxAmount;
            total += row.totalAmount;
            return row;
        });

        return {
            clientData: row.clientData,
            products,
            grandTotal: total,
        }
    }

    const generatePdf = async () => {
        try {
            const content = document.getElementById('ipdf');
            html2canvas(content, {
                onclone: function (clonedDoc) {

                    // I made the div hidden and here I am changing it to visible
                    clonedDoc.getElementById('ipdf').style.display = 'block';
                }
            }
            ).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                pdf.save('Invoice.pdf');
            });
            setPdfData({})
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        if (pdfData && Object.keys(pdfData).length) {
            generatePdf();
        }
    }, [pdfData])

    const searchData = async (event) => {
        let response = await api.get('invoice', { searchName: event.target.value });
        if (response) {
            setSalesData(response.data.data);
        }
    }

    return (
        <div>
            <Card className='saleCard' disablePadding>
                <CardContent>
                    <div className='saleBox'>
                        <Filter />
                        <div className='iconBox'>
                            <SearchBar  searchData={searchData} />
                            <Link to="/create-invoice">
                                <Fab color="primary" aria-label="add" size="medium">
                                    <AddIcon />
                                </Fab>
                            </Link>
                        </div>
                    </div>
                    <TableContainer className='shadow-none' component={Paper}>
                        <Table sx={{ minWidth: 650, overflowX: 'auto' }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Invoice Number</TableCell>
                                    <TableCell align="left">Date</TableCell>
                                    <TableCell align="left">Client Name</TableCell>
                                    <TableCell align="left">Items</TableCell>
                                    <TableCell align="left">Amount</TableCell>
                                    <TableCell align="left">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                    ? salesData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : salesData
                                ).map((row, i) => (
                                    <TableRow key={row._id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="left">
                                            {i + 1}
                                        </TableCell>
                                        <TableCell align="left">
                                            {"19-11-2022"}
                                        </TableCell>
                                        <TableCell align="left">{row.clientData.name.toUpperCase()}</TableCell>
                                        <TableCell align="left">{row.products.length}</TableCell>
                                        <TableCell align="left">{row.grandTotal}</TableCell>
                                        <TableCell align="left">
                                            <Button onClick={() => setPdfData(convertPdfData(row))}>
                                                <FileDownloadOutlinedIcon />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    {/* <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                        colSpan={10}
                                        count={salesData.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: { 'aria-label': 'rows per page' },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    /> */}
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        component="div"
                        count={salesData.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[5,10,25,50,100]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </CardContent>
            </Card>

            <div id="ipdf" style={{ display: 'none' }}>
                <div >
                    <InvoicePdf productData={pdfData.products || []} client={pdfData.clientData || {}} grandTotal={pdfData.grandTotal || 0} profile={profile} />
                </div>
            </div>
        </div>
    )
}
