class Images {
    constructor() {
        // eslint-disable-next-line no-undef
        this.s3Url = process.env.REACT_APP_S3_BUCKET_API_URL;
    }

    getImageUrl(fileName, isFetchedImg) {
        let imageUrl = '';
        if (isFetchedImg) {
            imageUrl = `${this.s3Url}/${fileName}`;
        } else {
            imageUrl = `${this.s3Url}/temp/${fileName}`;
        }
        return imageUrl;
    }

    imageSizes() {
        return {
            microsmall: { height: 75, width: 75 },
            small: { height: 100, width: 100 },
            medium: { height: 175, width: 175 },
            large: { height: 100, width: 500 },
            banner: { height: 500, width: 500 }
        };
    }

    imageFormats() {
        return {
            png: 'png',
        };
    }
}

export default Images;
